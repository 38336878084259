import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}



const routes = [
    { path: '/', redirect: '/Index' },
    {
        // 首页
        path: '/index',
        name: 'Index',
        component: () =>
            import ('./home/index.vue')
    },
    // 从首页进入的搜索页面
    {
        path: '/Search_page',
        name: 'Search_page',
        component: () =>
            import ('./home/Search_page.vue')

    },
    //段子
    {

        path: '/Segment',
        name: 'Segment',
        component: () =>
            import ('./cross/segment.vue')

    },
    //短剧本
    {

        path: '/Short_play',
        name: 'Short_play',
        component: () =>
            import ('./short_play/short_play.vue')

    },
    //短文      
    {

        path: '/Essay',
        name: 'Essay',
        component: () =>
            import ('./essay/essay.vue')

    },
    //定制服务 
    {

        path: '/Customization',
        name: 'Customization',
        component: () =>
            import ('./customization/customization.vue')

    },
    // 代理申请
    {
        path: '/Aget_apply',
        name: 'Aget_apply',
        component: () =>
            import ('./customization/agent_apply.vue')

    },
    //我的团队
    {
        path: '/My_team',
        name: 'myteam',
        component: () =>
            import ('./customization/my_team.vue')
    },
    //银行卡
    {
        path: '/cardBank',
        name: 'caradbank',
        component: () =>
            import ('./customization/bankvard.vue')
    },

    //提现
    {
        path: '/withdrawal',
        name: 'withdrawal',
        component: () =>
            import ('./customization/withdrawal.vue')
    },

    // 资金明细
    // detailed
    {
        path: '/detailed',
        name: 'detailed',
        component: () =>
            import ('./customization/detailed.vue')
    },
    //代运营
    {

        path: '/Operation',
        name: 'Operation',
        component: () =>
            import ('./run/operation.vue')

    },
    //行业资讯 
    {

        path: '/Information',
        name: 'Information',
        component: () =>
            import ('./information/information.vue')

    },
    //正文      
    {

        path: '/Text',
        name: 'Text',
        component: () =>
            import ('./information/text.vue')

    },
    //内容详情
    {

        path: '/Content',
        name: 'Content',
        component: () =>
            import ('./information/content.vue')

    },
    // 开通VIP
    {

        path: '/Vip',
        name: 'Vip',
        component: () =>
            import ('./home/vip.vue')

    },
    //支付页面
    {

        path: '/Pay',
        name: 'Pay',
        component: () =>
            import ('./pay/pay.vue')
    },

    //个人中心  personal
    {

        path: '/Personal',
        name: 'Personal',
        component: () =>
            import ('./home/personal.vue')

    },
    //修改资料  
    {
        path: '/Amend',
        name: 'Amend',
        component: () =>
            import ('./home/amend.vue')
    },
    //关于我们
    {
        path: '/Guanyu',
        name: 'Guanyu',
        component: () =>
            import ('./overall/guanyu.vue')
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router