import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
// 引用element ui
import element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import '../static/css/iconfont.css'

Vue.use(element);
import "../node_modules/element-ui/lib/theme-chalk/index.css";
Vue.config.productionTip = false;

Vue.prototype.http = "/api";
Vue.prototype.$axios = axios;
import axios from "axios";
import "../src/icon/iconfont.css";

// 全局底部导航
import tabBar from "./overall/bottom/overall.vue";
Vue.component("bottom", tabBar);

//头部导航
import tops from "./overall/head/top.vue";
Vue.component("top", tops);

// 首页头部导航
import index_tops from "./index_top.vue";
Vue.component("tops", index_tops);

// 侧边导航栏
import sidebar from "./overall/sidebar/sidebar.vue";
Vue.component("sidebar", sidebar);

//改变之后的头部
import change from "./overall/top/change.vue";
Vue.component("change", change);


Vue.prototype.colorkeyword = function brightenKeyword(val) {
    if (!this.keywords) {
        return val
    }
    //提高关键字亮度
    try {
        const replaceReg = new RegExp(this.keywords, "ig");
        if (val && replaceReg.test(val)) {
            val = val.replace(
                replaceReg,
                `<span style="background-color: rgb(251, 255, 0)">${this.keywords}</span>`
            );
        }
        return val && val.toString().toUpperCase();
    } catch (e) {
        console.log(e);
        return val;
    }
};


router.beforeEach((to, from, next) => {
    // chrome

    document.body.scrollTop = 0;

    // firefox

    document.documentElement.scrollTop = 0;

    // safari

    window.pageYOffset = 0;

    next();
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");