import { render, staticRenderFns } from "./change.vue?vue&type=template&id=8416f1bc&scoped=true&"
var script = {}
import style0 from "./change.vue?vue&type=style&index=0&id=8416f1bc&scoped=true&lang=css&"
import style1 from "./change.vue?vue&type=style&index=1&id=8416f1bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8416f1bc",
  null
  
)

export default component.exports