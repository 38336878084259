<template>
  <div id="app" :class="isMember?'':'not_member'">
    <!--  v-if="isRouterAlive" -->
    <router-view></router-view>
  </div>
</template>

<script>
    export default {
        name: "app",
        provide() {
            return {
                reload: this.reload,
            };
        },
        data() {
            return {
                isRouterAlive: true,
                isMember: false
            };
        },
        mounted() {
            var key = JSON.parse(window.sessionStorage.getItem("key"))
            console.log(key)
            if (key?.membertime > 0) {
                this.isMember = true
            }
        },
        methods: {
            reload() {
                this.isRouterAlive = false;
            },
        },
    };
</script>
<style>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    
    input[type="number"] {
        -moz-appearance: textfield;
    }
    
    input {
        -webkit-user-select: text !important;
        user-select: text !important;
    }
    
    .not_member {
        /* -moz-user-select: -moz-none;
        -moz-user-select: none;
        -o-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none; */
    }
    .not_member li,
    .not_member ol{
        -moz-user-select: -moz-none;
        -moz-user-select: none;
        -o-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    @font-face {
    font-family: 'iconfont';  /* Project id 2759271 */
    src: url('//at.alicdn.com/t/font_2759271_fnd5hib76rb.woff2?t=1629464364437') format('woff2'),
        url('//at.alicdn.com/t/font_2759271_fnd5hib76rb.woff?t=1629464364437') format('woff'),
        url('//at.alicdn.com/t/font_2759271_fnd5hib76rb.ttf?t=1629464364437') format('truetype');
    }
</style>