<template>
  <div>
    <div class="sidebar_box">
      <!-- 微信 -->
      <div class="one" @mouseenter="hideTaggle(false)" @mouseleave="hideTaggle()">
        <div class="vx">
          <div class="iconfont icon-gongzhonghao"></div>
        </div>
        <div class="vx_window" ref="vx_window" >
          <!-- 三角形 -->
          <div class="triangle_vw"></div>
          <!-- 内容 -->
          <div class="content">
            <!-- 二维码 -->
            <div class="code">
              <img :src="dataa.wxImg1" alt="" />
							<div class="text">
								<p>微信扫一扫添加</p>
								<p>我要短剧本官方微信</p>
							</div>
            </div>
            <!-- 联系 -->
            
          </div>
        </div>
      </div>
      <!-- 剧本定制 -->
      <div class="two">
        <div class="qq">
          <div class="two_text" @click="toCustomization" @mouseenter="mouseenter" @mouseleave="mouseleave">剧本 定制</div>
        </div>
        <div class="two_box" ref="two_box" v-show="hide">
          <!-- 三角形 -->
          <div class="triangle_qq"></div>
          <!-- 内容 -->
          <div class="txt">
						<div class="txt_p">
								<p>剧本定制、⽂章/软⽂代写、⽂案策划等服务，
										最快当天交稿，点击提交需求 ，定制属于你的剧本！</p>
						</div>
						<button class="btn" @click="toCustomization">去定制剧本</button>
						<button class="close" @click="close">
							<img src="../../assets/4758f9f5-f966-4340-ba03-61089b8a0105.png" alt="">
						</button>
					</div>
        </div>
      </div>
      <!-- 返回顶部 -->
      <div class="three">
        <div class="top">
          <a href="#">
            <!-- 返回顶部图标 -->
            <div class="iconfont icon-fanhuidingbu"></div>
            <!-- 返回顶部文字 -->
            <div class="iconfont icon-lujing460"></div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import axios from "axios";
    export default {
        data() {
            return {
                dataa: {},
                serviceqq: "",
                qQ: "",
                hide: true
            };
        },
        methods: {
            close() {
                this.hide = false
                    // this.$refs.two_box.style.display = 'none'
            },
            mouseenter() {
                this.hide = true
            },
            mouseleave() {
                // const time = setTimeout(() => {
                // this.$refs.two_box.style.display = "none"
                // }, 1000)
            },
            toCustomization() {
                this.$router.push({
                    path: '/customization?page=3',
                })
            },
            hideTaggle(v) {
                this.hide = v
            }
        },
        mounted() {
            axios.get(this.http + "/shopmini/wap_Bottom.html").then((res) => {
                this.dataa = res.data;
                this.serviceqq = this.dataa.serviceqq;
                this.qQ =
                    "https://wpa.qq.com/msgrd?" +
                    "v=3&uin=" +
                    this.serviceqq +
                    "&site=qq&menu=yes";
                // console.log(this.serviceqq);
            });
        },
    };
</script>
<style scoped>
    .sidebar_box {
        width: 60px;
        background: #ffffff;
        border-radius: 6px;
        z-index: 999;
        position: fixed;
        top: 50%;
        right: 0;
        margin-top: -90px;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    }
    /* 微信 */
    
    .one {
        width: 60px;
        height: 60px;
        cursor: pointer;
    }
    
    .vx {
        width: 60px;
        height: 60px;
    }
    /* 三角 */
    
    .triangle_vw {
        width: 8px;
        height: 8px;
        background-color: white;
        position: absolute;
        top: 20px;
        right: -5px;
        transform: rotate(45deg);
    }
    
    .vx_window {
        width: 145px;
        height: 180px;
        background-color: white;
        position: relative;
        right: 155px;
        top: -60px;
        display: none;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    }
    
    .vx {
        width: 40px;
        height: 60px;
        margin: auto;
    }
    
    .icon-gongzhonghao {
        font-size: 30px;
        text-align: center;
        line-height: 60px;
        color: #999999;
        border-bottom: 1px #999999 dashed;
    }
    
    .icon-gongzhonghao:hover {
        color: #f62959;
    }
    
    .one:hover.one>.vx_window {
        display: block;
    }
    /* QQ */
    
    .tow {
        width: 60px;
        height: 60px;
    }
    
    .qq {
        width: 40px;
        height: 60px;
        cursor: pointer;
        margin: auto;
        border-bottom: 1px #999999 dashed;
    }
    
    .two_text {
        font-weight: bold;
        padding-top: 5px;
    }
    
    .two:hover .qq .two_text {
        color: #f62959;
    }
    
    .two_box {
        width: 167px;
        height: 215px;
        background: #f62959;
        position: absolute;
        top: 50px;
        right: 60px;
        background: url(../../assets/e94a3ed9-5f8e-4116-9e11-0b00cfa88971.png);
        background-size: 100%;
    }
    
    .two_box {
        /* display: none; */
    }
    
    .two:hover.two>.two_box {
        display: block;
    }
    /* .two_item:hover {
        color: #F62959;
    }
    
    .two_item:hover .qq_window {
        display: block;
    } */
    
    .two_item {
        padding-top: 6px;
        color: #999;
    }
    /* .iconfont:hover {
        color: #f62959;
    } */
    
    .triangle_qq {
        width: 8px;
        height: 8px;
        background-color: white;
        position: absolute;
        top: 50%;
        right: -5px;
        margin-top: -73px;
        transform: rotate(45deg);
    }
    /* .icon-QQ {
        font-size: 30px;
        text-align: center;
        line-height: 60px;
        color: #999999;
    } */
    
    .qq_window {
        width: 167px;
        height: 215px;
        background: url(../../assets/e94a3ed9-5f8e-4116-9e11-0b00cfa88971.png);
        background-size: 100%;
        background-position-x: -5px;
        position: relative;
        border: 1px solid #0000;
        right: 155px;
        z-index: 9999;
        top: -121px;
        /* display: none; */
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    }
    /* .tow:hover.tow>.qq_window {
        display: block;
    } */
    /* 返回顶部 */
    
    .top {
        width: 40px;
        height: 60px;
        margin: auto;
        cursor: pointer;
    }
    
    .icon-fanhuidingbu {
        font-size: 28px;
        text-align: center;
        line-height: 60px;
        color: #999999;
        cursor: pointer;
    }
    
    .top:hover .icon-fanhuidingbu {
        display: none;
    }
    /* 悬停后显示的 */
    
    .icon-lujing460 {
        font-size: 30px;
        text-align: center;
        line-height: 60px;
        color: #f62959;
        display: none;
    }
    
    .top:hover .icon-lujing460 {
        display: block;
    }
    /* 联系 */
    
    .content {
        width: 100%;
        /* height: 205px; */
        /* padding-top: 15px; */
    }
    
    .code {
        width: 110px;
        height: 110px;
        border: 1px #707070 solid;
        margin: 0 auto;
    }
    
    .code img {
        width: 110px;
        height: 110px;
    }
    
    .text {
        width: 110px;
        margin: auto;
        text-align: center;
        margin-top: 10px;
    }
    
    .text p {
        font-size: 12px;
        color: #333333;
    }
    
    .txt {
        width: 130px;
        margin: 70px auto 0;
        color: #000;
    }
    
    .txt_p {
        font-size: 12px;
        margin: 0 auto;
    }
    
    .btn {
        width: 121px;
        height: 23px;
        background: #F62858;
        margin-top: 15px;
        border-radius: 4px;
        font-size: 12px;
        color: #ffffff;
    }
    
    .close {
        width: 9px;
        background: transparent;
        position: relative;
        top: -159px;
        left: -2px;
    }
    
    .close img {
        width: 9px;
    }
</style>