<template>
  <div>
    <div class="one">
      <!-- 头部 -->
      <div class="head">
        <!-- logo -->
        <div class="logo-box">
          <!-- logo图 -->
          <div class="logo" @click="toIndex">
            <img src="../../assets/home/logo.png" alt="" />
          </div>
        </div>

        <!-- 头部导航栏 -->

        <div class="top-font_box">
          <ul>
            <li
              @click="toIndex"
              :class="indexState ? 'red' : ''"
              class="ine"
              v-on:mouseover="mouIndex"
              v-on:mouseout="handIndex"
            >
              首页
            </li>          
            <li
              v-for="(item, index) in NavigationBarData"
              :key="item.id"
              v-on:mouseover="showToggle(index)"
              v-on:mouseout="handleHide"
              :class="{ red: index == activeIndex || index == clkIndex }"
              @click="toPage(item.title, index)"
            >
              {{ index!=4?item.title:'' }}
              <!-- <img style="width: 79%;margin-top: 4px;" v-if="index==4" src="../../assets/aaa/dong.gif" alt=""> -->
                <span v-if="index==4">短视频业务</span>
            </li>
          </ul>
       
          <div
            class="he"
            v-show="onOff"
            v-on:mouseover="onChangeState"
            v-on:mouseout="offChangeState"
          >
            <div class="he-box he-box_a">
              <div
                class="box-content"
                v-for="(item, index) in NavigationBarDataChildren"
                :key="item.id"
                @click="toDetail(item, index)"
              >
                <div class="he_content">
                  <div
                    v-if="
                      item.name == '全部段子' ||
                      item.name == '全部剧本' ||
                      item.name == '全部短文'
                    "
                  >
                    <div class="he_lfe he_lfe_a_a"></div>
                    <div class="he_lfe he_lfe_a_a_h"></div>
                  </div>
                  <div v-else-if="item.name == '沙雕段子'">
                    <div class="he_lfe he_lfe_a_b"></div>
                    <div class="he_lfe he_lfe_a_b_h"></div>
                  </div>
                  <div v-else-if="item.name == '搞笑段子'">
                    <div class="he_lfe he_lfe_a_c"></div>
                    <div class="he_lfe he_lfe_a_c_h"></div>
                  </div>
                  <div v-else-if="item.name == '内涵段子'">
                    <div class="he_lfe he_lfe_a_d"></div>
                    <div class="he_lfe he_lfe_a_d_h"></div>
                  </div>
                  <!-- ----------------------------------------------------------------------- -->
                  <div v-else-if="item.name == '搞笑反转'">
                    <div class="he_lfe he_lfe_b_a"></div>
                    <div class="he_lfe he_lfe_b_a_h"></div>
                  </div>
                  <div v-else-if="item.name == '宠物萌宠'">
                    <div class="he_lfe he_lfe_b_b"></div>
                    <div class="he_lfe he_lfe_b_b_h"></div>
                  </div>
                  <div v-else-if="item.name == '购物营销'">
                    <div class="he_lfe he_lfe_b_c"></div>
                    <div class="he_lfe he_lfe_b_c_h"></div>
                  </div>
                  <div v-else-if="item.name == '家庭生活'">
                    <div class="he_lfe he_lfe_b_d"></div>
                    <div class="he_lfe he_lfe_b_d_h"></div>
                  </div>
                  <div v-else-if="item.name == '社会职场'">
                    <div class="he_lfe he_lfe_b_e"></div>
                    <div class="he_lfe he_lfe_b_e_h"></div>
                  </div>
                  <div v-else-if="item.name == '校园生活'">
                    <div class="he_lfe he_lfe_b_f"></div>
                    <div class="he_lfe he_lfe_b_f_h"></div>
                  </div>
                  <div v-else-if="item.name == '情侣日常'">
                    <div class="he_lfe he_lfe_b_g"></div>
                    <div class="he_lfe he_lfe_b_g_h"></div>
                  </div>
                  <div v-else-if="item.name == '正能量'">
                    <div class="he_lfe he_lfe_b_h"></div>
                    <div class="he_lfe he_lfe_b_h_h"></div>
                  </div>
                  <div v-else-if="item.name == '沙雕'">
                    <div class="he_lfe he_lfe_b_i"></div>
                    <div class="he_lfe he_lfe_b_i_h"></div>
                  </div>
                  <div v-else-if="item.name == '单人'">
                    <div class="he_lfe he_lfe_b_j"></div>
                    <div class="he_lfe he_lfe_b_j_h"></div>
                  </div>
                  <div v-else-if="item.name == '多人'">
                    <div class="he_lfe he_lfe_b_k"></div>
                    <div class="he_lfe he_lfe_b_k_h"></div>
                  </div>
                  <!-- ----------------------------------------------------------------------- -->
                  <div v-else-if="item.name == '心灵鸡汤'">
                    <div class="he_lfe he_lfe_c_a"></div>
                    <div class="he_lfe he_lfe_c_a_h"></div>
                  </div>
                  <div v-else-if="item.name == '经典美文'">
                    <div class="he_lfe he_lfe_c_b"></div>
                    <div class="he_lfe he_lfe_c_b_h"></div>
                  </div>
                  <div v-else-if="item.name == '情感小品'">
                    <div class="he_lfe he_lfe_c_c"></div>
                    <div class="he_lfe he_lfe_c_c_h"></div>
                  </div>
                  <div v-else-if="item.name == '灵异故事'">
                    <div class="he_lfe he_lfe_c_d"></div>
                    <div class="he_lfe he_lfe_c_d_h"></div>
                  </div>
                  <div v-else-if="item.name == '娱乐小品'">
                    <div class="he_lfe he_lfe_c_e"></div>
                    <div class="he_lfe he_lfe_c_e_h"></div>
                  </div>
                  <div v-else-if="item.name == '寓言童话'">
                    <div class="he_lfe he_lfe_c_f"></div>
                    <div class="he_lfe he_lfe_c_f_h"></div>
                  </div>
                  <div v-else-if="item.name == '演讲公文'">
                    <div class="he_lfe he_lfe_c_g"></div>
                    <div class="he_lfe he_lfe_c_g_h"></div>
                  </div>
                  <div v-else-if="item.name == '神话传说'">
                    <div class="he_lfe he_lfe_c_h"></div>
                    <div class="he_lfe he_lfe_c_h_h"></div>
                  </div>
                  <div v-else>
                    <div class="he_lfe"></div>
                    <div class="he_lfe he_lfe_a_a_h"></div>
                  </div>
                  <div class="he_font">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>     
          <div
            class="he boxLeftChange"
            v-show="inforState"
            v-on:mouseover="onInfoState"
            v-on:mouseout="offInfoState"
          >
            <div class="he-box d he-box_d">
              <!-- 包裹内容 -->
              <div
                class="box-content"
                v-for="(item, index) in InformationTitleData"
                :key="item.id"
                @click="toInforDetail(index)"
              >
                <!-- 内容 -->
                <div class="he_content box-content_a">
                  <div v-if="item.title == '运营教程'">
                    <div class="he_lfe he_lfe_u_a"></div>
                    <div class="he_lfe he_lfe_u_a_h"></div>
                  </div>
                  <div v-else-if="item.title == '行业资讯'">
                    <div class="he_lfe he_lfe_u_b"></div>
                    <div class="he_lfe he_lfe_u_b_h"></div>
                  </div>
                  <div v-else>
                    <div class="he_lfe he_lfe_u_b"></div>
                    <div class="he_lfe he_lfe_u_b_h"></div>
                  </div>
                  <!-- 文字 -->
                  <div class="he_font">{{ item.title }}</div>
                </div>
              </div>
              <!-- 包裹内容 -->
              <!-- <div class="box-content">
              <div class="he_content box-content_a">
                <div class="he_lfe he_lfe_v"></div>
                <div class="he_lfe he_lfe_v_v"></div>
                <div class="he_font">行业资讯</div>
              </div>
            </div> -->
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!-- 登录注册 -->
        <div class="enters" @click="qq">
          <div :class="{ entersaaa: entersbbb }">{{ register }}</div>
        </div>
        <!-- 右上角个人头像 -->
        <div class="renters">
          <img
            style="cursor: pointer"
            :src="userimgaaa"
            alt=""
            :class="{ dew: !entersbbb }"
            @click="personaldata"
          />
          <!-- 鼠标滑过个人头像 -->
        </div>
      </div>
    </div>
    <!-- 登录注册 -->
    <!-- 盒子 -->
    <div :class="{ enter_box: qwe, demo: asd }">
      <div class="enter" @click.stop="ww('isBox')">
        <!-- 中间的盒子 -->
        <div class="enter_content">
          <!-- 关闭按钮 -->
          <div class="close_button_box" @click.stop="ww('notBox')">
            <i><img src="../../assets/home/166.png" alt="" /></i>
          </div>
          <!-- 左 -->
          <div class="enter_left">
            <img src="../../assets/home/99.png" alt="" />
          </div>
          <!-- 右 -->
          <div class="box_asd">
            <div :class="{ enter_rightaaa: erbtn }">
              <!-- 二维码 -->
              <div class="QR_code_content">
                <!-- 标题 -->
                <div class="enter_title">
                  <p>微信登录</p>
                </div>
                <!-- 扫码并关注 -->
                <div class="enter_text_box">
                  <div class="enter_text">
                    <p>扫码并关注<span>我要短剧本</span>公众号，安全快捷登录</p>
                  </div>
                </div>
                <!-- 二维码 登录 验证码 -->
                <div class="QR_code_box">
                  <!-- 二维码 -->
                  <div class="QR_code">
                    <img :src="userimg" alt="" />
                  </div>
                </div>
                <!-- 扫码即为同意协议 -->
                <div class="enter_bottom_box">
                  <div class="enter_bottom">
                    <input checked type="checkbox" />
                    <label for=""
                      >扫码登录即表示您同意并遵守<span>用户协议</span></label
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 手机号注册 -->
            <div :class="{ rnter_rightbbb: erbtna }" v-show="!phoneOk">
              <div class="QR_code_content">
                <!-- 标题 -->
                <div class="enter_title">
                  <p>绑定手机号码</p>
                </div>
                <!-- 扫码并关注 -->
                <div class="enter_text_box">
                  <div class="enter_text">
                    <p>您已关注公众号，绑定手机号码后即可注册成功</p>
                  </div>
                </div>
                <!-- 二维码 登录 验证码 -->
                <div class="QR_code_box">
                  <!-- 登录 -->
                  <div class="number1" style="border-radius: 25px">
                    <!-- 图片 -->
                    <div class="number_icon">
                      <img src="../../assets/home/130.png" alt="" />
                    </div>
                    <!-- 输入手机号 -->
                    <div class="number-input" style="border-radius: 25px">
                      <input
                        type="tel"
                        name="text1"
                        maxlength="11"
                        v-model="deml"
                        placeholder="输入手机号码"
                        oninput="value=value.replace(/[^\d]/g,'')"
                      />
                    </div>
                  </div>
                  <!-- 验证码 -->
                  <div class="verify">
                    <!-- 图片 -->
                    <div class="verify_icon">
                      <img src="../../assets/home/131.png" alt="" />
                    </div>
                    <!-- 输入验证码 -->
                    <div class="verify-input">
                      <input
                        type="tel"
                        name="text1"
                        maxlength="11"
                        v-model="demla"
                        placeholder="输入手机验证码"
                        oninput="value=value.replace(/[^\d]/g,'')"
                      />
                    </div>
                    <!-- 点击获取验证码 -->
                    <div class="verify_button">
                      <button @click="codea" :disabled="isdisable">
                        {{ content }}
                      </button>
                    </div>
                  </div>
                  <!-- 登录完成按钮 -->
                  <div class="complete">
                    <button @click="accomplish">完成</button>
                  </div>
                </div>
                <!-- 扫码即为同意协议 -->
                <div class="enter_bottom_box">
                  <div class="enter_bottom">
                    <input checked type="checkbox" />
                    <label
                      >扫码登录即表示您同意并遵守<span>用户协议</span></label
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 客服二维码图片 -->
            <div class="kferwm" v-show='is_pop==1 && phoneOk'>
              <img :src="kfData.img" alt="">
              <div class="text" v-if="kfData">
                <div v-for="(item,index) of kfData.title.split('|')" :key="index">
                  <div v-show="index==0" class="textOne">
                    <img src="../../assets/wx.png" alt="">
                    <div>{{item}}</div>
                  </div>
                  <div v-show="index!=0">{{item}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    // import axios from "axios";
    import Qs from "qs";
    import {
        getNavigationBar,
        getInformationTitle,
        getIndexguild,
        getindexQR,
    } from "../../http";
    export default {
        props: {
            isOpen: Number
        },
        data() {
            return {
                phoneOk: false, //手机号注册完成
                is_pop: 0, //1-显示客服二维码 0-不显示
                kfData: '', //客服信息
                userdeml: true,
                xufei: "",
                vipnumber: true,
                huiyuanzhi: "",
                erbtna: true,
                erbtn: false,
                isdisable: false,
                indexState: true,
                inforState: false,
                activeIndex: -1,
                onOff: false,
                state: false,
                NavigationBarData: [],
                NavigationBarDataChildren: [],
                InformationTitleData: [],
                clkIndex: -1,
                detailDataFather: "",
                obj: {},
                index: 0,
                qwe: true,
                asd: true,
                qrimg: [],
                userimg: [],
                register: "登录 | 注册",
                userimgaaa: [],
                entersbbb: false,
                // 从sessionStorage中获取用户的类型
                valuea: [],
                ding: "",
                dataa: [],
                deml: "",
                demla: "",
                content: "发送验证码", // 按钮里显示的内容
                totalTime: 60, //记录具体倒计时时间
                clock: "",
                val: "",
                ticket: "",
            };
        },
        watch: {
            isOpen(e) {
                if (e == 1) {
                    this.qq()
                }
                return e
            }
        },
        created() {
            let page = this.$route.query.page;
            this.clkIndex = page;
            this.activeIndex = page;
            if (!this.$route.query.page && this.$route.query.page !== 0) {
                this.indexState = true;
            } else {
                this.indexState = false;
            }
            // 获取导航栏里的数据
            this.localGetData();
            this.localGetEndData();
            //从sessionStorage中获取用户的类型
            var infosr = sessionStorage.getItem("key");
            var keyaaa = JSON.parse(infosr);
            this.valuea = keyaaa;
            // console.log("424", this.valuea);
            if (this.valuea?.class == 0) {
                this.vipnumber = true;
                this.huiyuanzhi = "暂未开通会员";
                this.xufei = "会员充值";
                // this.userdeml = true;
            } else if (keyaaa?.class == 1) {
                this.vipnumber = false;
                this.huiyuanzhi = "月度会员";
                this.xufei = "会员续费";
                // this.userdeml = false;
            } else if (keyaaa?.class == 2) {
                this.vipnumber = false;
                this.huiyuanzhi = "季度会员";
                this.xufei = "会员续费";
                // this.userdeml = false;
            } else if (keyaaa?.class == 3) {
                this.vipnumber = false;
                this.huiyuanzhi = "年度会员";
                this.xufei = "会员续费";
                // this.userdeml = false;
            } else {
                // console.log(111);
            }
        },
        mounted() {
            if (!this.valuea?.uid || !this.valuea?.openid || !this.valuea?.phone) {
                // console.log(1);
                this.entersbbb = false;
            } else {
                // console.log(2);
                this.entersbbb = true;
                this.userimgaaa = this.valuea.headimgurl;
            }

        },
        methods: {
            // 获取客服
            getKf() {
                this.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_Service.html",
                    }).then((res) => {
                        this.kfData = res.data.data[0];
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
            // 页面刷新
            winReload(cond) {
                window.location.reload();
            },
            // 二维码保持监听用户信息接口
            async GetuserDEN() {
                let that = this;
                this.$axios({
                        method: "get",
                        url: "/api/shopmini/wap_GetuserDEN.html?data=" + that.dataa + "&ticket=" + that.ticket,
                    }).then(function(res) {
                        if (res.data) {
                            // console.log(res);
                            that.dataa = res.data;
                            that.val = that.dataa.data.uid;
                            // that.reload();
                            // 有手机号
                            if (that.valuea.phone != "") {
                                // 登陆注册框隐藏
                                that.asd = true;
                                that.winReload();
                                that.cellphonea = "手机号：" + that.valuea.phone;
                                clearInterval(that.ding);
                            } else {
                                // console.log("失败");
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
            // 登录注册按钮
            qq() {
                var that = this;
                this.qrcode();
                this.asd = false;
                this.ding = setInterval(() => {
                    this.GetuserDEN();
                    if (this.dataa.static == 1) {

                        // this.judge = 1;
                        // 关注成功后隐藏二维码显示手机号验证
                        this.erbtna = false;
                        this.erbtn = true;
                        // this.asd = true;
                        // 加载个人信息
                        // this.tourist = false;
                        // 关闭定时器
                        clearInterval(this.ding);
                        // 存储数据
                        // console.log(this.dataa);
                        // 存储接口个人信息资料
                        sessionStorage.setItem("key", JSON.stringify(this.dataa.data));
                        this.valuea = JSON.parse(sessionStorage.getItem("key"));
                        this.userimgaaa = this.valuea.headimgurl;
                        // this.session();
                        console.log(this.valuea);
                    } else {
                        // console.log("失败");
                    }
                }, 1000);
                // console.log(that.valuea)
                if (that.val != "" && !that.valuea?.phone) { // 扫码过没有注册账号
                    // 关闭定时器
                    clearInterval(this.ding);
                    that.$message({
                        message: "请注册账号",
                        type: "warning",
                        duration: 2000,
                    });
                    that.asd = false; //注册遮罩显示
                    // 手机号注册显示开始
                    that.erbtna = false;
                    that.erbtn = true;
                    // 手机号显示结束
                    return;
                }
                if (this.valuea?.phone) {
                    this.asd = true;
                    this.userimgaaa = this.valuea.headimgurl;
                    clearInterval(this.ding);
                } else {
                    // console.log("失败");
                }
            },
            // 点击获取验证码
            codea() {
                // 获取is_pop 1-显示客服二维码 0-不显示
                this.is_pop = sessionStorage.getItem('is_pop');
                if (this.is_pop == 1) {
                    this.getKf();
                }
                var that = this;
                if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(this.deml)) {
                    that.$message({
                        message: "手机号码有误，请重填",
                        type: "warning",
                    });
                } else {
                    this.cellphone();
                    // alert(this.phone);
                }

                this.content = this.totalTime + "s后重新发送"; //这里解决60秒不见了的问题
                this.clock = setInterval(() => {
                    this.isdisable = true;
                    this.totalTime--;
                    this.content = this.totalTime + "s后重新发送";
                    if (this.totalTime == 0) {
                        //当倒计时小于0时清除定时器
                        clearInterval(this.clock);
                        this.content = "重新发送验证码";
                        this.totalTime = 60;
                        this.isdisable = false;
                    }
                }, 1000);
            },
            // 向后端传递手机号  验证码  id
            async Msgbdphoneaaa() {
                // 获取is_pop 1-显示客服二维码 0-不显示
                this.is_pop = sessionStorage.getItem('is_pop');
                if (this.is_pop == 1) {
                    this.getKf();
                }
                let that = this;
                that.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_Msgbdphone.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            tel: that.deml,
                            code: that.demla,
                            uid: that.val,
                        }),
                    })
                    .then(function(res) {
                        if (res.data.statuc == 1) {
                            that.$message({
                                message: "注册绑定成功",
                                type: "success",
                            });
                            if (that.is_pop == 0) {
                                // 关闭登录注册框
                                that.asd = true;
                                that.winReload();
                            } else {
                                that.phoneOk = true;
                            }

                            sessionStorage.setItem("key", JSON.stringify(res.data.data)); //个人信息重新赋值
                            that.valuea = JSON.parse(sessionStorage.getItem("key"));
                            clearInterval(that.clock);
                            that.userimgaaa = that.valuea.headimgurl;
                        } else {
                            that.$message({
                                message: res.data.msg,
                                type: "warning",
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
            // 登录注册关闭按钮
            ww(isBox) {
                // 判断点击的是否是整个div 并且是在客服二维码页
                if (isBox == 'isBox' && !this.phoneOk) {
                    return;
                }
                this.asd = true;
                clearInterval(this.ding);
                if (this.is_pop == 1) {
                    this.winReload();
                }
            },
            // 手机验证完成按钮
            accomplish() {
                this.Msgbdphoneaaa();
                clearInterval(this.clock);
            },
            //从sessionStorage获取导航栏
            localGetData() {
                let localData = sessionStorage.getItem("tabCloum");
                if (localData == null) {
                    //获得导航栏数据
                    this.getNavigationBarData();
                } else {
                    let localDataOK = JSON.parse(localData);
                    this.NavigationBarData = localDataOK;
                    //判断导航栏数据与最新数据是否相同
                    this.judgeData();
                }
            },
            //从sessionStorage获取导航栏End
            localGetEndData() {
                let localDataEnd = sessionStorage.getItem("tabCloumEnd");
                //   console.log(localDataEnd);
                if (localDataEnd == null) {
                    //获得导航栏数据
                    this.getInformationTitleData();
                    // console.log("End数据为null");
                } else {
                    let localDataEndOK = JSON.parse(localDataEnd);
                    this.InformationTitleData = localDataEndOK;
                    //   判断导航栏数据与最新数据是否相同
                    this.judgeDataEnd();
                }
            },
            //获得导航栏数据
            async getNavigationBarData() {
                let navgationinfo = JSON.parse(window.sessionStorage.getItem("headnavinfo"));

                if (!navgationinfo) {
                    let NavigationBarData = await getNavigationBar();
                    this.NavigationBarData = NavigationBarData;
                    window.sessionStorage.setItem("headnavinfo", JSON.stringify(this.NavigationBarData));
                } else {
                    this.NavigationBarData = navgationinfo
                }

                // let SegmentIndex = NavigationBarData.findIndex(
                //     (item) => item.title == "段子"
                // );
                // let Short_playIndex = NavigationBarData.findIndex(
                //     (item) => item.title == "短剧本"
                // );
                // let EssayIndex = NavigationBarData.findIndex(
                //     (item) => item.title == "短文"
                // );
                // let obj1 = {
                //     id: -1,
                //     name: "全部段子",
                // };
                // let obj2 = {
                //     id: -1,
                //     name: "全部剧本",
                // };
                // let obj3 = {
                //     id: -1,
                //     name: "全部短文",
                // };
                // this.NavigationBarData[SegmentIndex].Content.unshift(obj1);
                // this.NavigationBarData[Short_playIndex].Content.unshift(obj2);
                // this.NavigationBarData[EssayIndex].Content.unshift(obj3);
                // this.NavigationBarData = NavigationBarData;
                let tabCloum = JSON.stringify(this.NavigationBarData);
                sessionStorage.setItem("tabCloum", tabCloum);
            },
            //获得导航栏行业资讯标题
            async getInformationTitleData() {
                // console.log("123");
                let InformationTitleData = await getInformationTitle();
                // console.log(InformationTitleData);
                this.InformationTitleData = InformationTitleData;

                let tabCloumEnd = JSON.stringify(InformationTitleData);
                sessionStorage.setItem("tabCloumEnd", tabCloumEnd);
            },
            //判断请求导航栏数据和请求导航栏数据是否相同
            async judgeData() {
                let data = await getNavigationBar();
                // let SegmentIndex = data.findIndex((item) => item.title == "段子");
                // let Short_playIndex = data.findIndex((item) => item.title == "短剧本");
                // let EssayIndex = data.findIndex((item) => item.title == "短文");
                // let obj1 = {
                //     id: -1,
                //     name: "全部段子",
                // };
                // let obj2 = {
                //     id: -1,
                //     name: "全部剧本",
                // };
                // let obj3 = {
                //     id: -1,
                //     name: "全部短文",
                // };
                // data[SegmentIndex].Content.unshift(obj1);
                // data[Short_playIndex].Content.unshift(obj2);
                // data[EssayIndex].Content.unshift(obj3);
                if (this.NavigationBarData == data) {
                    return;
                } else {
                    this.NavigationBarData = data;
                    let tabCloum = JSON.stringify(data);
                    sessionStorage.setItem("tabCloum", tabCloum);
                }
            },
            //判断请求导航栏数据和请求导航栏数据是否相同
            async judgeDataEnd() {
                let data = await getInformationTitle();
                if (this.InformationTitleData == data) {
                    return;
                } else {
                    this.InformationTitleData = data;
                    let tabCloum = JSON.stringify(data);
                    sessionStorage.setItem("tabCloumEnd", tabCloum);
                }
            },
            // 二维码
            async qrcode() {
                let that = this;
                let Base64 = require('js-base64').Base64;
                var referinfo = this.getUrlinfo("refer");
                var referbase = Base64.decode(referinfo);
                this.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_UserContent.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            data: this.userimg,
                            refer: referbase
                        }),
                    })
                    .then(function(res) {
                        // console.log(res);
                        that.userimg = res.data.data;
                        that.ticket = res.data.ticket; //微信关注公众号唯一标识符
                        // console.log(this.userimg);
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
            getUrlinfo(variable) {
                var query = window.location.search.substring(1);
                var vars = query.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    if (pair[0] == variable) {
                        return pair[1];
                    }
                }
                return "";
            },
            // 手机号验证码
            async cellphone() {
                let that = this;
                this.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_Sendmsg.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            tel: that.deml,
                            uid: that.valuea.uid,
                        }),
                    })
                    .then(function(res) {
                        // console.log(res.data.msg);
                        if (res.data.statuc == 1) {
                            that.$message({
                                message: res.data.msg,
                                type: "success",
                            });
                        } else {
                            that.$message({
                                message: res.data.msg,
                                type: "warning",
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
            //导航栏鼠标移上
            showToggle(index) {
                //移上获得当前下标的children
                this.index = index;
                if (index == 5) {
                    var Children = this.InformationTitleData;
                } else {
                    // this.detailDataFather = this.NavigationBarData[index].title;
                    this.detailDataFather = index;
                    // console.log(this.NavigationBarData[index]);
                    this.NavigationBarDataChildren = this.NavigationBarData[index].Content;
                    // console.log(this.NavigationBarData[index].Content);
                    var Children = this.NavigationBarData[index].Content;
                }
                if (Children.length <= 0) {
                    this.onOff = false;
                    this.inforState = false;
                } else {
                    if (index == 5) {
                        this.inforState = true;
                    } else {
                        //移上的时候 开关打开
                        this.onOff = true;
                    }
                }
                this.activeIndex = index;
            },
            //导航栏鼠标移开
            handleHide() {
                this.onOff = false;
                this.inforState = false;
                this.activeIndex = this.clkIndex;
                if (!this.$route.query.page && this.$route.query.page !== 0) {
                    this.indexState = true;
                } else {
                    this.indexState = false;
                }
            },
            //div鼠标移上
            onChangeState() {
                this.onOff = true;
            },
            //div鼠标移开
            offChangeState() {
                // console.log('123');
                this.onOff = false;
            },

            //行业资讯鼠标移上
            onInfoState() {
                this.inforState = true;
            },
            //行业资讯鼠标移开
            offInfoState() {
                this.inforState = false;
            },
            //点击行业资讯内容
            toInforDetail(i) {
                // console.log(i);
                // console.log(this.index);
                this.$router.push({
                    name: "Information",
                    query: {
                        page: this.index,
                        count: i,
                    },
                });
            },
            //index鼠标移上
            mouIndex() {
                this.indexState = true;
                this.activeIndex = -1;
            },
            //index鼠标移开
            handIndex() {
                // console.log(this.activeIndex);
                this.activeIndex = this.clkIndex;
                if (!this.$route.query.page && this.$route.query.page !== 0) {
                    this.indexState = true;
                } else {
                    this.indexState = false;
                }
            },
            // 点击头像进行跳转个人中心页面
            personaldata() {
                this.$router.push({
                    path: "/personal",
                });
            },
            // 点击跳转首页
            toIndex() {
                // if (!this.clkIndex&&this.$route.query.page!==0) {
                this.indexState = true;
                // }
                this.$router.push({
                    path: "/Index",
                    // query: {
                    //   page: -1,
                    // },
                });
            },
            //一级跳转页面
            toPage(name, index) {
                // console.log(index);
                this.indexState = false;
                if (index == 0) {
                    this.$router.push({
                        name: "Segment",
                        query: {
                            page: this.activeIndex,
                        },
                    });
                } else if (index == 1) {
                    this.$router.push({
                        name: "Short_play",
                        query: {
                            page: index,
                        },
                    });
                } else if (index == 2) {
                    this.$router.push({
                        name: "Essay",
                        query: {
                            page: index,
                        },
                    });
                } else if (index == 3) {
                    this.$router.push({
                        name: "Customization",
                        query: {
                            page: index,
                        },
                    });
                } else if (index == 4) {
                    this.$router.push({
                        name: "Operation",
                        query: {
                            page: index,
                        },
                    });
                } else if (index == 5) {
                    this.$router.push({
                        name: "Information",
                        query: {
                            page: index,
                        },
                    });
                }
            },
            //二级单独跳转页面
            toDetail(e, index) {
                if (this.detailDataFather == 0) {
                    this.$router.push({
                        name: "Segment",
                        query: {
                            page: this.index,
                            count: index,
                        },
                    });
                } else if (this.detailDataFather == 1) {
                    this.$router.push({
                        name: "Short_play",
                        query: {
                            page: this.index,
                            count: e.id,
                        },
                    });
                } else if (this.detailDataFather == 2) {
                    this.$router.push({
                        name: "Essay",
                        query: {
                            page: this.index,
                            count: e.id,
                        },
                    });
                } else if (this.detailDataFather == 3) {
                    this.$router.push({
                        name: "Customization",
                        query: {
                            page: this.index,
                            count: index,
                        },
                    });
                }
            },
            // 会员充值按钮
            xufeia() {
                this.$router.push("/Vip");
            },
            // 个人中心按钮
            coliect() {
                this.$router.push({
                    path: "/Personal",
                    query: {
                        pagaac: 0,
                    },
                });
            },
            //  个人订单按钮
            order() {
                this.$router.push({
                    path: "/Personal",
                    query: {
                        pagaac: 2,
                    },
                });
            },
            //  个人福利按钮
            welfare() {
                this.$router.push({
                    path: "/Personal",
                    query: {
                        pagaac: 1,
                    },
                });
            },
        },
    };
</script>
<style scoped>
    /* 头部 */
    /* #company_name{
  text-align: center;
  white-space:nowrap;
  font-size: 18px;
  line-height: 24px;
  font-weight: 900;
  color: #333;
} */
    
    .one {
        width: 100%;
        min-width: 1200px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        /* padding-bottom: 10px; */
        z-index: 999;
        position: fixed;
        top: 0;
    }
    
    .ine {
        width: 50px;
        /* background-color: green; */
        line-height: 60px;
        box-sizing: border-box;
        /* float: left; */
    }
    /* .ine:hover { */
    /* color: red; */
    /* background-color: green; */
    /* } */
    
    .head {
        width: 1200px;
        margin: auto;
        display: flex;
        position: relative;
        align-items: center;
    }
    /* logo图盒子 */
    
    .logo-box {
        width: 150px;
        height: 45px;
    }
    /* logo图图片 */
    
    .logo {
        width: 160px;
        height: 45px;
        cursor: pointer;
    }
    
    .logo img {
        width: 100%;
    }
    /* 头部导航栏 */
    
    .top-font_box {
        width: 800px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        margin-left: 50px;
        /* border: 1px solid red; */
    }
    /* 头部文字 */
    
    .red {
        color: #f62959;
        cursor: pointer;
        border-bottom: 2px solid red;
        box-sizing: border-box;
    }
    
    ul {
        width: 800px;
        list-style: none;
        display: flex;
    }
    
    ul li {
        width: 120px;
        height: 60px;
        line-height: 60px;
        text-align: center;
    }
    
    .he {
        /* width: 900px; */
        backdrop-filter: blur(20px);
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 0px 0px 10px 10px;
        padding: 10px;
        /* padding-top: 30px; */
        position: absolute;
        max-width: 874px;
        left: 160px;
        top: 60px;
        display: flex;
        flex-wrap: wrap;
        z-index: 9999999999;
        box-sizing: border-box;
    }
    
    .boxLeftChange {
        /* background-color: skyblue; */
        left: 818px;
        /* top: 60px; */
    }
    /* 外面的盒子 */
    
    .he-box_a {
        display: flex;
        flex-wrap: wrap;
    }
    /* 里面的内容 */
    
    .he_content {
        width: 144px;
        height: 64px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px rgba(0, 0, 0, 0.1) solid;
        color: #333333;
    }
    
    .he_content:hover {
        box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.3);
    }
    
    .box-content_a {
        border: none;
    }
    /* 包裹的内容 */
    
    .box-content {
        padding: 12px;
    }
    /* 精灵图 */
    
    .he_lfe {
        width: 24px;
        height: 24px;
        background-image: url("../../assets/elf.png");
    }
    /* 段子中的图片段子中的图片段子中的图片段子中的图片段子中的图片段子中的图片段子中的图片 */
    /* 沙雕段子 */
    
    .he_lfe_a {
        width: 24px;
        height: 24px;
        /* background-position: 100px 10px; */
    }
    /* 文字 */
    
    .he_font {
        margin-left: 10px;
        cursor: pointer;
    }
    /* 搜索框 */
    
    .top-input-box {
        width: 166px;
        height: 40px;
        border-radius: 25px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        background-color: #f5f5f5;
    }
    
    .top-input-box:hover {
        border: 1px red solid;
    }
    
    .top-input-icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .top-input-icon>i>img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
    
    .top-input input {
        width: 90%;
        font-size: 14px;
        background: none;
        vertical-align: middle;
    }
    
    .top-input input:checked {
        color: #666666;
        font-size: 14px;
    }
    /* 登录注册 */
    
    .enters {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        /* margin-left: 10px; */
        color: #f62959;
        position: absolute;
        right: 30px;
    }
    
    .renters img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-left: 50px;
    }
    
    .enters div {
        cursor: pointer;
    }
    /* 右上角登录注册 */
    
    .entersaaa {
        display: none;
    }
    
    .dew {
        display: none;
    }
    
    .enter-midden img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        vertical-align: middle;
    }
    
    .enter>.enter-midden {
        font-weight: bold;
    }
    /* 有头像时显示的内容 */
    /* 头部悬停效果展示区域 */
    
    .navigation {
        padding-bottom: 10px;
    }
    
    .q {
        width: 600px;
        height: 200px;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: -1;
        z-index: 100;
        position: absolute;
        top: 70px;
        display: none;
    }
    
    .tow_box:hover>.q {
        display: block;
    }
    /* 头部导航 */
    
    .wei_a>ul {
        display: flex;
        justify-content: center;
    }
    
    .wei>ul>li:hover {
        color: #f62959;
    }
    
    .c {
        width: 850px;
    }
    
    .b {
        width: 680px;
    }
    
    .d {
        left: 850px;
    }
    /* 悬停 */
    
    .he_content {
        cursor: pointer;
    }
    
    .he_content:hover {
        background-color: #f62959;
        color: #ffffff;
    }
    
    .he_content:hover>.he_lfe {
        display: none;
    }
    /* 精灵图 */
    
    .he_lfe {
        width: 24px;
        height: 24px;
        background-image: url("../../assets/elf.png");
    }
    /* 段子段子段子段子段子段子段子段子段子段子段子段子段子段子段子段子 */
    /* 全部段子 */
    /* 这是全部段子鼠标悬停后显示的 */
    /* .he_lfe_a_a {
background-position: -102px 34px;
}

.he_content:hover .he_lfe_a_a {
display: block !important;
} */
    
    .he_content:hover .he_lfe {
        display: none;
    }
    /* 段子中的沙雕段子 */
    /* 登录注册 */
    
    .enter_box {
        width: 100vw;
        height: 100vh;
        /* background-color: red; */
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 999;
        position: fixed;
        top: 0;
    }
    
    .demo {
        display: none;
    }
    
    .close_button_box {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: #a7aab3;
        position: absolute;
        right: 0;
        top: -44px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .close_button_box img {
        width: 34px;
        height: 34px;
        vertical-align: middle;
        transition: 0.3s;
    }
    
    .close_button_box img:hover {
        transform: rotate(90deg);
    }
    
    .enter {
        width: 100vw;
        height: 100vh;
        /* background-color: red; */
        background-color: rgba(255, 255, 255, 0.2);
    }
    /* 中间内容 */
    
    .enter_content {
        width: 800px;
        height: 480px;
        background-color: rgba(255, 255, 255, 1);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -240px;
        margin-left: -400px;
        display: flex;
        z-index: 999;
    }
    /* 左 */
    
    .enter_left {
        width: 50%;
    }
    
    .enter_left img {
        width: 400px;
        height: 480px;
        vertical-align: middle;
    }
    /* 右 */
    
    .box_asd {
        width: 400px;
        /* border: 1px red solid; */
    }
    
    .enter_rightaaa {
        width: 50%;
        display: none;
    }
    
    .rnter_rightbbb {
        width: 50%;
        display: none;
    }
    /* 标题 */
    
    .enter_title {
        width: 100%;
        height: 90px;
        line-height: 90px;
        border-bottom: 1px #e5e5e5 solid;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: unset;
    }
    /* 文字 */
    
    .enter_text_box {
        width: 100%;
        height: 40px;
        margin-top: 20px;
    }
    
    .enter_text {
        width: 86.5%;
        height: 40px;
        border-radius: 20px;
        background-color: #fee9ee;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
    
    .enter_text p {
        color: #333333;
        font-size: 14px;
    }
    
    .enter_text span {
        color: #f62959;
    }
    /* 二维码 登录 验证码*/
    /* 二维码 */
    
    .QR_code_box {
        width: 100%;
        height: 222px;
        background-origin: 1px red solid;
        margin-top: 20px;
    }
    
    .QR_code {
        width: 222px;
        height: 222px;
        border: 1px solid #707070;
        margin: auto;
    }
    
    .QR_code img {
        width: 222px;
        height: 222px;
    }
    /* 手机号码 */
    
    .number1 {
        width: 308px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #f62959 !important;
        border-radius: 25px !important;
        box-shadow: 0px 0px 6px 0px rgba(246, 41, 89, 0.2);
        margin: auto;
        display: flex;
        align-items: center;
    }
    /* 前面的图标 */
    
    .number_icon {
        width: 19%;
        height: 30px;
        border-right: 1px #e5e5e5 solid;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .number_icon i,
    .number_icon i img {
        width: 20px;
        height: 20px;
    }
    /* 输入框 */
    
    .number-input {
        width: 70%;
        margin-left: 10px;
    }
    
    .number-input input {
        width: 90%;
        height: 30px;
    }
    /* 验证码 */
    
    .verify {
        width: 308px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #f62959;
        border-radius: 25px;
        box-shadow: 0px 0px 6px 0px rgba(246, 41, 89, 0.2);
        margin: auto;
        display: flex;
        align-items: center;
        margin-top: 20px;
    }
    /* 前面的图标 */
    
    .verify_icon {
        width: 19%;
        height: 30px;
        /* border-right: 1px #E5E5E5 solid; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .verify_icon i,
    .verify_icon i img {
        width: 20px;
        height: 20px;
    }
    /* 输入框 */
    
    .verify-input {
        width: 40%;
        margin-left: 10px;
    }
    
    .verify-input input {
        width: 86%;
        height: 30px;
    }
    /* 获取验证码的按钮 */
    
    .verify_button {
        margin-right: 8px;
    }
    
    .verify_button button {
        width: 107px;
        height: 34px;
        color: white;
        background: #f62959;
        border-radius: 17px;
        box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.1);
    }
    /* 登录完成的按钮 */
    
    .complete {
        width: 308px;
        height: 50px;
        margin: auto;
    }
    
    .complete button {
        width: 308px;
        height: 50px;
        background: #f62959;
        color: white;
        border-radius: 25px;
        box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.2);
        margin-top: 20px;
        font-size: 18px;
    }
    /* 下面的文字 */
    
    .enter_bottom_box {
        width: 100%;
        height: 30px;
        margin-top: 20px;
    }
    
    .enter_bottom {
        width: 74.5%;
        height: 20px;
        margin: auto;
    }
    
    .enter_bottom input {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border: 1px #999999 solid;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 10px;
    }
    
    .enter_bottom input:checked {
        background-image: url(../../assets/home/129.png);
        background-size: 100%;
        background-repeat: no-repeat;
        border: none;
    }
    
    .enter_bottom label {
        color: #333333;
        font-size: 14px;
        margin-left: 10px;
    }
    
    .enter_bottom label span {
        color: #f62959;
        cursor: pointer;
    }
    
    button:hover {
        background-color: #d6244e;
    }
    /* 广告 */
    
    .advertising_box {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        position: fixed;
        top: 0;
        display: none;
    }
    
    .advertising {
        width: 600px;
        height: 600px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -300px;
        margin-left: -300px;
    }
    
    .advertising_image {
        width: 600px;
        height: 490px;
    }
    
    .advertising_image img {
        width: 600px;
        height: 490px;
    }
    /* 按钮 */
    
    .advertising_button {
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .advertising_button button {
        width: 150px;
        height: 50px;
        background: linear-gradient(90deg, #eac583, #dca545);
        border-radius: 25px;
        box-shadow: 0px 3px 6px 0px rgba(159, 116, 68, 0.1);
        font-size: 20px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
    }
    /* 鼠标滑过头像 */
    
    .portrait {
        width: 300px;
        height: 300px;
        background: rgb(187, 186, 186);
        /* background-color: red; */
        position: absolute;
        right: 100px;
        display: none;
    }
    
    .renters:hover>div {
        display: block;
    }
    
    .portrait-a {
        width: 300px;
        height: 50px;
        background-image: url("../../assets/home/txbj.png");
        background-repeat: no-repeat;
        line-height: 50px;
    }
    
    .portrait-a img {
        width: 30px;
        height: 30px;
    }
    
    .portrait-a span {
        margin: 0 10px 0 5px;
    }
    
    .portrait-b {
        width: 300px;
        height: 40px;
        background: rgb(187, 186, 186);
        /* background-color: tan; */
        line-height: 40px;
        font-size: 15px;
    }
    
    .portrait-b>p {
        margin-left: 10px;
    }
    
    .portrait-b>p>img {
        width: 25px;
        height: 25px;
        vertical-align: middle;
        margin-left: -5px;
        margin-right: 4px;
        margin-top: -2px;
    }
    
    .portrait-b span {
        color: #f62959;
    }
    
    .portrait-c {
        width: 90%;
        height: 40px;
        margin: 0 auto;
        background: #3a4458;
        line-height: 40px;
        border-radius: 5px;
    }
    
    .portrait-c span {
        margin-left: 10px;
        font-size: 13px;
        color: #e0b88a;
    }
    
    .portrait-c button {
        width: 80px;
        height: 30px;
        background-color: #e0b88a;
        border-radius: 20px;
        margin-left: 10px;
    }
    
    .portrait-d {
        width: 90%;
        height: 30px;
        background: rgb(187, 186, 186);
        margin: 0 auto;
        margin-top: 10px;
        line-height: 30px;
    }
    
    .portrait-d span {
        cursor: pointer;
    }
    
    .portrait-d>span>img {
        width: 23px;
        height: 23px;
        margin-left: -1px;
        vertical-align: middle;
        margin-right: 5px;
    }
    /* 新加的内容 */
    
    .he_lfe_a_a {
        background-position: 0 0;
    }
    
    .he_lfe_a_a_h {
        background-position: -102px 0;
        display: none;
    }
    
    .he_lfe_a_b {
        background-position: 0 -34px;
    }
    
    .he_lfe_a_b_h {
        background-position: -102px -34px;
        display: none;
    }
    
    .he_lfe_a_c {
        background-position: 0px -68px;
    }
    
    .he_lfe_a_c_h {
        display: none;
        background-position: -102px -68px;
    }
    
    .he_lfe_a_d {
        background-position: 0px -102px;
    }
    
    .he_lfe_a_d_h {
        display: none;
        background-position: -102px -102px;
    }
    
    .he_lfe_b_a {
        background-position: 0px -136px;
    }
    
    .he_lfe_b_a_h {
        display: none;
        background-position: -102px -136px;
    }
    
    .he_lfe_b_b {
        background-position: 0px -169px;
    }
    
    .he_lfe_b_b_h {
        display: none;
        background-position: -102px -169px;
    }
    
    .he_lfe_b_c {
        background-position: 0 -204px;
    }
    
    .he_lfe_b_c_h {
        display: none;
        background-position: -102px -204px;
    }
    
    .he_lfe_b_d {
        background-position: 0px -238px;
    }
    
    .he_lfe_b_d_h {
        display: none;
        background-position: -102px -238px;
    }
    
    .he_lfe_b_e {
        background-position: 0px -272px;
    }
    
    .he_lfe_b_e_h {
        display: none;
        background-position: -102px -272px;
    }
    
    .he_lfe_b_f {
        background-position: 0px -304px;
    }
    
    .he_lfe_b_f_h {
        display: none;
        background-position: -102px -304px;
    }
    
    .he_lfe_b_g {
        background-position: 0px -340px;
    }
    
    .he_lfe_b_g_h {
        display: none;
        background-position: -102px -340px;
    }
    
    .he_lfe_b_h {
        background-position: 0px -372px;
    }
    
    .he_lfe_b_h_h {
        display: none;
        background-position: -102px -372px;
    }
    
    .he_lfe_b_i {
        background-position: 0px -408px;
    }
    
    .he_lfe_b_i_h {
        display: none;
        background-position: -102px -408px;
    }
    
    .he_lfe_b_j {
        background-position: 0px -408px;
    }
    
    .he_lfe_b_j_h {
        display: none;
        background-position: -102px -408px;
    }
    
    .he_lfe_b_k {
        background-position: 0px -408px;
    }
    
    .he_lfe_b_k_h {
        display: none;
        background-position: -102px -408px;
    }
    /* 短文 */
    
    .he_lfe_c_a {
        background-position: 0px -442px;
    }
    
    .he_lfe_c_a_h {
        display: none;
        background-position: -102px -442px;
    }
    
    .he_lfe_c_b {
        background-position: -34px 0px;
    }
    
    .he_lfe_c_b_h {
        display: none;
        background-position: -136px -0px;
    }
    
    .he_lfe_c_c {
        background-position: -34px -34px;
    }
    
    .he_lfe_c_c_h {
        display: none;
        background-position: -136px -34px;
    }
    
    .he_lfe_c_d {
        background-position: -34px -68px;
    }
    
    .he_lfe_c_d_h {
        display: none;
        background-position: -136px -68px;
    }
    
    .he_lfe_c_e {
        background-position: -34px -100px;
    }
    
    .he_lfe_c_e_h {
        display: none;
        background-position: -136px -100px;
    }
    
    .he_lfe_c_f {
        background-position: -34px -137px;
    }
    
    .he_lfe_c_f_h {
        display: none;
        background-position: -136px -137px;
    }
    
    .he_lfe_c_g {
        background-position: -34px -170px;
    }
    
    .he_lfe_c_g_h {
        display: none;
        background-position: -136px -170px;
    }
    
    .he_lfe_c_h {
        background-position: -34px -204px;
    }
    
    .he_lfe_c_h_h {
        display: none;
        background-position: -136px -204px;
    }
    
    .he_lfe_u_a {
        background-position: -34px -237px;
    }
    
    .he_lfe_u_a_h {
        display: none;
        background-position: -136px -237px;
    }
    
    .he_lfe_u_b {
        background-position: -34px -272px;
    }
    
    .he_lfe_u_b_h {
        display: none;
        background-position: -136px -272px;
    }
    /* .he_content:hover .he_lfe_a_b,
.he_content:hover .he_lfe_a_c {
display: none;
} */
    /* 客服二维码 */
    
    .kferwm {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 16px;
    }
    
    .kferwm img {
        margin-bottom: 20px;
        max-width: 200px;
        max-height: 200px;
    }
    
    .kferwm .text {
        display: flex;
        align-items: center;
    }
    
    .kferwm>div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .textOne {
        display: flex;
    }
    
    .kferwm .text img {
        width: 20px;
        height: 20px;
        margin-bottom: 0px;
        margin-right: 5px;
    }
    
    .he_content:hover .he_lfe_a_a_h,
    .he_content:hover .he_lfe_a_b_h,
    .he_content:hover .he_lfe_a_c_h,
    .he_content:hover .he_lfe_a_d_h,
    .he_content:hover .he_lfe_b_a_h,
    .he_content:hover .he_lfe_b_b_h,
    .he_content:hover .he_lfe_b_c_h,
    .he_content:hover .he_lfe_b_d_h,
    .he_content:hover .he_lfe_b_e_h,
    .he_content:hover .he_lfe_b_f_h,
    .he_content:hover .he_lfe_b_g_h,
    .he_content:hover .he_lfe_b_h_h,
    .he_content:hover .he_lfe_b_i_h,
    .he_content:hover .he_lfe_b_j_h,
    .he_content:hover .he_lfe_b_k_h,
    .he_content:hover .he_lfe_c_a_h,
    .he_content:hover .he_lfe_c_b_h,
    .he_content:hover .he_lfe_c_c_h,
    .he_content:hover .he_lfe_c_d_h,
    .he_content:hover .he_lfe_c_e_h,
    .he_content:hover .he_lfe_c_f_h,
    .he_content:hover .he_lfe_c_g_h,
    .he_content:hover .he_lfe_c_h_h,
    .he_content:hover .he_lfe_u_a_h,
    .he_content:hover .he_lfe_u_b_h {
        display: block !important;
    }
</style>



<!-- <div class="portrait"> -->
<!-- 个人信息 -->
<!-- <div class="portrait-a" v-if="valuea==null">
                            <img src="../../assets/home/1.png" alt="" style="vertical-align: middle;">
                            <span>昵称</span>
                            <span>手机号</span>
                        </div>
                        <div class="portrait-a" v-else>
                            <img :src="valuea.headimgurl" alt="" style="vertical-align: middle;">
                            <span>{{valuea.realname}}</span>
                            <span>({{valuea.phone}})</span>
                        </div> -->
<!-- 会员类型 -->
<!-- <div class="portrait-b">
                            <p>会员类型：
                                <img src="../../assets/home/蒙版组 187.png" v-show="vipnumber" />
                                <img src="../../assets/home/蒙版组 25.png" v-show="!vipnumber" />
                                <span>{{huiyuanzhi}}</span>
                            </p>
                        </div> -->
<!-- 开通按钮 -->
<!-- <div class="portrait-c">
                            <span>开通会员后可解锁全站内容</span>
                            <button @click="xufeia">{{xufei}}</button>
                        </div> -->
<!-- 个人 -->
<!-- <div class="portrait-d">
                            <span @click="coliect">
                                <img src="../../assets/home/蒙版组 140.png" alt="">
                                个人中心
                            </span>
                        </div>
                        <div class="portrait-d">
                            <span @click="coliect">
                                <img src="../../assets/home/蒙版组 145.png" alt="">
                                我的收藏
                            </span>
                        </div>
                        <div class="portrait-d">
                            <span @click="welfare">
                                <img src="../../assets/home/蒙版组 146.png" alt="">
                                会员福利
                            </span>
                        </div>
                        <div class="portrait-d">
                            <span @click="order">
                                <img src="../../assets/home/蒙版组 147.png" alt="">
                                我的订单
                            </span>
                        </div>
                    </div> -->