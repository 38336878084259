import $axios from "axios";

// const $host = "http://192.168.1.111";
// const $host = "http://192.168.1.200";
// const $host = "/api";
const $host = "/api";
// const $host = "http://192.168.1.167";


//----------------------------------首页start-----------------------------------------
//首页搜索 ----- 请求资讯详情
export async function getIndexSearchOne(keyword, p) {
    let res;
    res = await $axios($host + "/shopmini/wap_Searchpc.html", {
        params: { keyword, p },
    });
    return res.data;
}
//首页行业质询搜索 ----- 请求资讯详情
export async function getIndexSearchTwo(keyword, p) {
    let res;
    res = await $axios($host + "/shopmini/wap_Journalism.html", {
        params: { keyword, p },
    });
    return res.data;
}
// 首页轮播
export async function getIndeximg() {
    let res;
    res = await $axios($host + "/shopmini/wap_Slideshow.html");
    return res.data;
}
// 首页视频
export async function getIndexviedo() {
    let res;
    res = await $axios($host + "/shopmini/wap_Mp4.html");
    return res.data;
}
// 首页短剧本标题
export async function getIndexbiao() {
    let res;
    res = await $axios($host + "/shopmini/wap_ClassContent.html");
    return res.data;
}
// 首页短剧本
export async function getIndexplay(id, keyword, p) {
    let res;
    res = await $axios($host + "/shopmini/wap_Crosstalk.html", {
        params: { id, keyword, p },
    });
    return res.data;
}
// 首页代运营
export async function getIndexmessage() {
    let res;
    res = await $axios($host + "/shopmini/wap_Operation.html");
    return res.data;
}
// 首页行业资讯标题
export async function getIndexguild() {
    let res;
    res = await $axios($host + "/shopmini/wap_Address.html");
    return res.data;
}
// 首页行业资讯列表内容
export async function getIndexcentent(id) {
    let res;
    res = await $axios($host + "/shopmini/wap_Industry.html", { params: { id } });
    return res.data;
}

//----------------------------------行业资讯start-----------------------------------------

//informationTitle ----- 行业资讯标题
export async function getInformationTitle() {
    let res;
    res = await $axios($host + "/shopmini/wap_Address.html");
    return res.data.getsecond;
}

//informationListData ----- 行业列表内容
export async function getInformationListData(id, p) {
    let res;
    res = await $axios($host + "/shopmini/wap_Industry.html", {
        params: { id, p },
    });
    return res.data;
}

//informationContent ----- 请求资讯详情
export async function getInformationContent(id) {
    let res;
    res = await $axios($host + "/shopmini/wap_IndustryContent.html", {
        params: { id },
    });
    return res.data.data[0];
}

//informationMessage ----- 请求会员福利
export async function getNounContentData(id) {
    let res;
    res = await $axios($host + "/shopmini/wap_NounContent.html", {
        params: { id },
    });
    return res.data.data[0];
}

//informationRecommendRead----- 请求推荐阅读
export async function getInformationReRead(id) {
    let res;
    res = await $axios($host + "/shopmini/wap_HotContents.html");
    return res.data.data;
}

//informationHotRead ----- 请求热门阅读
export async function getInformationHotRead(id) {
    let res;
    res = await $axios($host + "/shopmini/wap_HotContent.html");
    return res.data.data;
}

//informationMessage ----- 请求行业资讯
export async function getMessageData() {
    let res;
    res = await $axios($host + "/shopmini/wap_InquiryContents.html");
    return res.data.data;
}

//informationSearch ----- 搜索资讯--运营标题
export async function informationToSearch(name) {
    let res;
    res = await $axios($host + "/shopmini/wap_GetSearch.html", {
        params: { name },
    });
    return res.data.data;
}

//informationColumnImg ----- 栏目图
export async function getColumnImg() {
    let res;
    res = await $axios($host + "/shopmini/wap_InformationImg.html");
    return res.data.data;
}

//informationSearch ----- 行业资讯搜索
export async function getInformationSearch(p, name) {
    let res;
    res = await $axios($host + "/shopmini/wap_IndustrySearch.html", {
        params: { p, name },
    });
    return res.data;
}

//----------------------------------段子start-----------------------------------------

//segmentSearch ----- 段子搜索
export async function segmentSearch(keyword) {
    let res;
    res = await $axios($host + "/shopmini/wap_Lanyard.html", {
        params: { keyword },
    });
    return res.data;
}

//----------------------------------短剧本搜索start-----------------------------------------

//segmentSearch ----- 短剧本搜索
export async function shortPlaySearch(name) {
    let res;
    res = await $axios($host + "/shopmini/wap_GetConentet.html", {
        params: { name },
    });
    return res.data;
}

//----------------------------------短文搜索start-----------------------------------------

//segmentSearch ----- 短文搜索
export async function essaySearch(name) {
    let res;
    res = await $axios($host + "/shopmini/wap_EssayConentet.html", {
        params: { name },
    });
    return res.data;
}

// ---------------------------------------------------------------------
// 段子栏目图
export async function getsegmentcolumn() {
    let res;
    res = await $axios($host + "/shopmini/wap_SegmentImg.html");
    return res.data;
}
// 段子分类
export async function getsegmentclassify() {
    let res;
    res = await $axios($host + "/shopmini/wap_Paging.html");
    return res.data;
}
// 段子分类搜索
export async function getsegmentsearch(p, tags) {
    let res;
    res = await $axios($host + "/shopmini/wap_ClassTags.html", {
        params: { p, tags },
    });
    return res.data;
}

// ---------------------------------------------------------------------

// 导航栏
export async function getNavigationBar() {
    let res;
    res = await $axios($host + "/shopmini/wap_Head.html");
    // res = await $axios($host + "/shopmini/wap_Headss.html");
    return res.data;
}

// 段子分类搜索
// export async function getsegmentsearch(id) {
//     let res;
//     res = await $axios($host + "/shopmini/wap_ClassTags.html", { params: { id }, });
//     return res.data;
// }

// -----------------------------定制服务-----------------------------------

// 定制服务 栏目图
// export async function getCustmizationImg() {
//     let res;
//     res = await $axios($host + "/shopmini/wap_CustomizationImg.html");
//     return res.data.data;
// }

// -----------------------------短剧本-----------------------------------

// 短剧本分类
export async function getScriptClass() {
    let res;
    res = await $axios($host + "/shopmini/wap_ScriptClass.html");
    return res.data.data;
}

// 短剧本时间分类
export async function getScriptTime() {
    let res;
    res = await $axios($host + "/shopmini/wap_TimeClasss.html");
    return res.data.data;
}

// 短剧本人数分类
export async function getScriptPeopNum() {
    let res;
    res = await $axios($host + "/shopmini/wap_NumClass.html");
    return res.data.data;
}

// 短剧本分类搜索
// export async function getScriptClassSearch(p, tag, times, num) {
//     let res;
//     res = await $axios($host + "/shopmini/wap_GetConentetTegs.html", { params: { p, tag, times, num }, });
//     return res.data;
// }
export async function getScriptClassSearch(p, tag, times, num) {
    let res;
    res = await $axios($host + "/shopmini/wap_GetConentetTegs.html", {
        params: { p, tag, times, num },
    });
    return res.data;
}

// 短文页面栏目图
export async function getessayimg() {
    let res;
    res = await $axios($host + "/shopmini/wap_EssayImg.html");
    return res.data;
}
// 短文页面分类
export async function getessayclassify() {
    let res;
    res = await $axios($host + "/shopmini/wap_EssayClass.html");
    return res.data;
}
// 短文分类搜索
export async function getessaytage(p, tag) {
    let res;
    res = await $axios($host + "/shopmini/wap_EssayGetTage.html", {
        params: { p, tag },
    });
    return res.data;
}

// -----------------------------定制服务-----------------------------------

// 定制服务 栏目图
export async function getCustmizationImg() {
    let res;
    res = await $axios($host + "/shopmini/wap_CustomizationImg.html");
    return res.data.data;
}
// 定制服务 剧本定制
export async function getCustmizationplay() {
    let res;
    res = await $axios($host + "/shopmini/wap_CustomizationPlay.html");
    return res.data;
}
// 定制服务 有声录制
export async function getCustmizationsong() {
    let res;
    res = await $axios($host + "/shopmini/wap_CustomizationVoiced.html");
    return res.data;
}
// 定制服务 短视频
export async function getCustmizationvideo() {
    let res;
    res = await $axios($host + "/shopmini/wap_CustomizationMp4.html");
    return res.data;
}

// 获取定制分类
export async function getCustomizedClass() {
    let res;
    res = await $axios($host + "/shopmini/wap_CustomizationPlay.html");
    return res.data;
}

// 获取剧本套餐
export async function geTmeal() {
    let res;
    res = await $axios($host + "/shopmini/wap_Boutiquepack.html");
    return res.data;
}
// -----------------------------代运营-----------------------------------

// 代运营 栏目图
export async function getCoperatioonimg() {
    let res;
    res = await $axios($host + "/shopmini/wap_ThirdpartnarImg.html");
    return res.data;
}
// 代运营 轮播
export async function getCoperatioonimgs() {
    let res;
    res = await $axios($host + "/shopmini/wap_ThirdpartnarImgs.html");
    return res.data;
}

// -----------------------------段子 短文  短视频 详情-----------------------------------

// 段子 短文  短视频 详情
// export async function getAllContent(id, uid, login_key) {
//     let res;
//     res = await $axios($host + "/shopmini/wap_PieceContent.html", { params: { id, uid, login_key }, });
//     return res.data;
// }

// -----------------------------Text详情-----------------------------------

//informationRecommendRead----- 请求推荐阅读
export async function getTextReRead() {
    let res;
    res = await $axios($host + "/shopmini/wap_PiecereCommend.html");
    return res.data.data;
}

//informationHotRead ----- 请求热门阅读
export async function getTextHotRead() {
    let res;
    res = await $axios($host + "/shopmini/wap_PieceHot.html");
    return res.data.data;
}

//informationMessage ----- 请求行业资讯
export async function getTextMessageData() {
    let res;
    res = await $axios($host + "/shopmini/wap_IndustryCommend.html");
    return res.data.data;
}

// -----------------------------Text详情-----------------------------------

//getSearchTitle----- 请求搜索页标题
export async function getSearchTitle() {
    let res;
    res = await $axios($host + "/shopmini/wap_ClassContentjs.html");
    return res.data.data;
}
// --------------------------------------二维码----------------------------------------------
// 二维码
export async function getindexQR() {
    let res;
    res = await $axios($host + "/shopmini/wap_UserContent.html");
    return res.data;
}
// 二维码保持监听用户信息接口
// export async function getindexDEN() {
//     let res;
//     res = await $axios($host + "/shopmini/wap_GetuserDEN.html");
//     return res.data;
// }

// --------------------------------------搜索页----------------------------------------------
// 搜索页行业资讯搜索
export async function getSearchInforData(id, keywords, p) {
    let res;
    res = await $axios($host + "/shopmini/wap_Industryss.html", {
        params: { id, keywords },
    });
    if (res.data.stutic != 0) {
        return res.data.data;
    } else {
        return res.data;
    }
}

// --------------------------------------定制服务提交接口----------------------------------------------

export async function addContent(data) {
    let res;
    res = await $axios.post($host + "/shopmini/wap_AddContents.html", data);
    return res.data;
}
// --------------------------------------搜索下面热门标签接口----------------------------------------------

export async function Populartags() {
    let res;
    res = await $axios($host + "/shopmini/wap_Retitles.html");
    return res.data;
}

// --------------------------------------个人中心----------------------------------------------
// 个人中心收藏
// export async function getPersinalcollect(uid, login_key) {
//     let res;
//     res = await $axios($host + "/shopmini/wap_Usercollect.html", { params: { uid, login_key }, });
//     return res.data;
// }

// --------------------------------------text页面收藏点赞----------------------------------------------
// export async function textCollect(data) {
//     let res;
//     res = await $axios.post($host + "/shopmini/wap_Collect.html", data);
//     return res;
// }

// export async function textGiveLike(data) {
//     let res;
//     res = await $axios.post($host + "/shopmini/wap_GoLike.html", data );
//     return res;
// }
// export async function Msgbdphone() {
//     let res;
//     res = await $axios($host + "/api/shopmini/wap_Msgbdphone.html");
//     return res.data;
// }
//  -------------------------------vip-------------------------------------------------

// export async function Getvip() {
//     let res;
//     res = await $axios($host + "/shopmini/wap_Viptype.html");
//     return res.data;
// }