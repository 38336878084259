import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // teamuid: "",
    },
    mutations: {
        // teamuidfun(state, item) {
        //     state.teamuid = item;
        // }
    },
    actions: {},
    modules: {}
})