<template>
  <div>
    <div class="one">
			<!-- 头部 -->
			<div class="head">
				<!-- logo -->
				<div class="logo-box">
					<!-- logo图 -->
					<div class="logo">
						<img src="./assets/home/logo.png" alt="">
					</div>
				</div>
				<!-- 头部导航栏 -->
				<div class="top-font_box">
					<div class="a">
						<div class="wei wei_b" :class="{yangshi1:ys1,yangshi2:ys2}">
							<ul>
								<li @click="index">首页</li>
							</ul>
						</div>

					</div>
					<!-- 段子段子段子段子段子段子段子段子段子段子段子段子段子段子段子段子 -->
					<div class="a">
						<div class="wei wei_b">
							<ul>
								<li @click="Segment">段子</li>
							</ul>
						</div>
						<div class="he">
							<!-- 外面的盒子 -->
							<div class="he-box he-box_a">
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe"></div>
										<!-- 这是悬停之后的 -->
										<div class="he_lfe he_lfe_a_a"></div>
										<!-- 文字 -->
										<div class="he_font">全部段子</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_a"></div>
										<!-- 这是悬停之后的 -->
										<div class="he_lfe he_lfe_a_a_a"></div>
										<!-- 文字 -->
										<div class="he_font">沙雕段子</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_b"></div>
										<!-- 这是悬停之后的 -->
										<div class="he_lfe he_lfe_b_b"></div>
										<!-- 文字 -->
										<div class="he_font">搞笑段子</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_c"></div>
										<!-- 这是悬停之后的 -->
										<div class="he_lfe he_lfe_c_c"></div>
										<!-- 文字 -->
										<div class="he_font">内涵段子</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					<div class="a">
						<div class="wei wei_b">
							<ul>
								<li @click="Short_play">短剧本</li>
							</ul>
						</div>
						<div class="he c">
							<div class="he-box c he-box_a">
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe"></div>
										<!-- 这是悬停之后的 -->
										<div class="he_lfe he_lfe_a_a"></div>

										<!-- 文字 -->
										<div class="he_font">全部剧本</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_d"></div>
										<!-- 鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_d_d "></div>
										<!-- 文字 -->
										<div class="he_font">搞笑反转</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_e"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_e_e"></div>
										<!-- 文字 -->
										<div class="he_font">宠物萌宠</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_f"></div>
										<!-- 鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_f_f"></div>
										<!-- 文字 -->
										<div class="he_font">购物营销</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_g"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_g_g"></div>
										<!-- 文字 -->
										<div class="he_font">家庭生活</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_h"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_h_h"></div>
										<!-- 文字 -->
										<div class="he_font">社会职场</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_i"></div>
										<!-- 这是悬停之后的 -->
										<div class="he_lfe he_lfe_i_i"></div>
										<!-- 文字 -->
										<div class="he_font">校园生活</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_j"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_j_j"></div>
										<!-- 文字 -->
										<div class="he_font">情侣日常</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_k"></div>
										<!-- 这是悬停之后的 -->
										<div class="he_lfe he_lfe_k_k"></div>
										<!-- 文字 -->
										<div class="he_font">正能量</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_l"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_l_l"></div>
										<!-- 文字 -->
										<div class="he_font">沙雕</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 短文 -->
					<div class="a">
						<div class="wei wei_b">
							<ul>
								<li @click="Essay">短文</li>
							</ul>
						</div>
						<div class="he  b">
							<div class="he-box b he-box_a">
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_m"></div>
										<!-- 这是悬停之后的 -->
										<div class="he_lfe he_lfe_m_m"></div>
										<!-- 文字 -->
										<div class="he_font">心灵鸡汤</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_n"></div>
										<!-- 这是悬停之后的 -->
										<div class="he_lfe he_lfe_n_n"></div>
										<!-- 文字 -->
										<div class="he_font">经典美文</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_o"></div>
										<!-- 这是悬停之后的 -->
										<div class="he_lfe he_lfe_o_o"></div>
										<!-- 文字 -->
										<div class="he_font">情感小品</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_p"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_p_p"></div>
										<!-- 文字 -->
										<div class="he_font">灵异故事</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_q"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_q_q"></div>
										<!-- 文字 -->
										<div class="he_font">娱乐小品</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_r"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_r_r"></div>
										<!-- 文字 -->
										<div class="he_font">寓言童话</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_s"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_s_s"></div>
										<!-- 文字 -->
										<div class="he_font">演讲公文</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class="box-content">
									<!-- 内容 -->
									<div class="he_content">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_t"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_t_t"></div>
										<!-- 文字 -->
										<div class="he_font">神话传说</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 定制服务 -->
					<div class="a">
						<div class="wei wei_b">
							<ul>
                                <li @click="Operationa">定制服务</li>
							</ul>
						</div>
					</div>
					<!-- 代运营代运营代运营代运营代运营代运营代运营代运营代运营代运营代运营代运营 -->
					<div class="a">
						<div class="wei wei_b">
							<ul>
								<li @click="Operation">短视频业务</li>
							</ul>
						</div>
					</div>
					<div class="a">
						<div class="wei wei_b">
							<ul>
								<li @click="Information">行业资讯</li>
							</ul>
						</div>
						<div class="he d">
							<div class="he-box d he-box_d">
								<!-- 包裹内容 -->
								<div class="box-content ">
									<!-- 内容 -->
									<div class="he_content box-content_a">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_u"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_u_u"></div>
										<!-- 文字 -->
										<div class="he_font">运营教程</div>
									</div>
								</div>
								<!-- 包裹内容 -->
								<div class=" box-content ">
									<!-- 内容 -->
									<div class="he_content box-content_a">
										<!-- 精灵图 -->
										<div class="he_lfe he_lfe_v"></div>
										<!-- 这是鼠标悬停之后显示的 -->
										<div class="he_lfe he_lfe_v_v"></div>
										<!-- 文字 -->
										<div class="he_font">行业资讯</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 搜索栏 -->
				<div class="top-input-box">
					<!-- 图片 -->
					<div class="top-input-icon">
						<i><img src="./assets/home/seek.png" alt=""></i>
					</div>
					<div class="top-input">
						<input type="text" placeholder="搜索您想要的内容">
					</div>
				</div>
				<!-- 登录注册 -->
				<div class="enter">
					<div class="enter-left">登录</div>
					<div class="enter-midden">|</div>
          <div class="enter-right">注册</div>

          <!-- <div class="enter-left"> </div>
					<div class="enter-midden"><img src="./assets/home/1.png" alt=""></div>
          <div class="enter-right"> </div> -->
				</div>
			</div>
		</div>
		
  </div>
</template>
<script>
    export default {
        data() {
            return {
                ys1: true,
                ys2: false,
            }
        },
        methods: {
            // 首页
            index() {
                this.$router.push('/index')
                this.ys1 = !this.ys2
            },
            // 短剧本
            Short_play() {
                this.$router.push('/Short_play')

            },
            //段子
            Segment() {
                this.$router.push('/Segment')
            },
            //短文 Essay
            Essay() {
                this.$router.push('/Essay')
            },
            //行业资讯 Information
            Information() {
                this.$router.push('/Information')
            },
            //代运营 Operation
            Operation() {
                this.$router.push('/Operation')
            },
            // 定制服务
            Operationa() {
                this.$router.push('/Customization')
            }
        },
    }
</script>
<style scoped>
    /* 头部 */
    
    .one {
        width: 100%;
        min-width: 1200px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        /* padding-bottom: 10px; */
        z-index: 999;
        position: fixed;
        top: 0;
    }
    
    .head {
        width: 1200px;
        margin: auto;
        display: flex;
        position: relative;
        align-items: center;
    }
    /* logo图盒子 */
    
    .logo-box {
        width: 150px;
        height: 45px;
    }
    /* logo图图片 */
    
    .logo {
        width: 150px;
        height: 45px;
    }
    
    .logo img {
        width: 100%;
    }
    /* 头部导航栏 */
    
    .top-font_box {
        width: 700px;
        display: flex;
        justify-content: space-between;
        margin-left: 50px;
    }
    
    .yangshi1 {
        border-bottom: 3px solid red;
        color: red;
    }
    
    .yangshi2 {
        border-bottom: 1px solid white;
    }
    
    .wei {
        width: 98px;
    }
    /* .wei_b { */
    /* width: 65px; */
    /* color: red; */
    /* border-bottom: 2px solid red; */
    /* border-radius: 10px; */
    /* border-bottom-width: 1px solid red; */
    /* } */
    
    .wei>ul {
        display: flex;
        justify-content: center;
        line-height: 60px;
        text-align: center;
        padding-bottom: 10px;
    }
    
    .wei>ul>li {
        height: 50px;
        cursor: pointer;
    }
    
    .he {
        background-color: rgba(255, 255, 255, 0.97);
        display: none;
        border-radius: 0px 0px 10px 10px;
        padding: 10px;
        position: absolute;
        left: 200px;
        top: 60px;
    }
    
    .top-font_box>.a:hover>.he {
        display: block;
    }
    /* 外面的盒子 */
    
    .he-box_a {
        display: flex;
        flex-wrap: wrap;
    }
    /* 里面的内容 */
    
    .he_content {
        width: 144px;
        height: 64px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px rgba(0, 0, 0, 0.1) solid;
        color: #333333;
    }
    
    .he_content:hover {
        box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.3);
    }
    
    .box-content_a {
        border: none;
    }
    /* 包裹的内容 */
    
    .box-content {
        padding: 12px;
    }
    /* 精灵图 */
    
    .he_lfe {
        width: 24px;
        height: 24px;
        background-image: url('../src/assets/elf.png');
    }
    /* 段子中的图片段子中的图片段子中的图片段子中的图片段子中的图片段子中的图片段子中的图片 */
    /* 沙雕段子 */
    
    .he_lfe_a {
        width: 24px;
        height: 24px;
        /* background-position: 100px 10px; */
    }
    /* 文字 */
    
    .he_font {
        margin-left: 10px;
        cursor: pointer;
    }
    /* 搜索框 */
    
    .top-input-box {
        width: 166px;
        height: 40px;
        border-radius: 25px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        background-color: #F5F5F5;
    }
    
    .top-input-box:hover {
        border: 1px red solid;
    }
    
    .top-input-icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .top-input-icon>i>img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
    
    .top-input input {
        width: 90%;
        font-size: 14px;
        background: none;
        vertical-align: middle;
    }
    
    .top-input input:checked {
        color: #666666;
        font-size: 14px;
    }
    /* 登录注册 */
    
    .enter {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-left: 10px;
        color: #F62959;
        position: absolute;
        right: 0;
    }
    
    .enter div {
        cursor: pointer;
    }
    
    .enter-midden img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        vertical-align: middle;
    }
    
    .enter>.enter-midden {
        font-weight: bold;
    }
    /* 有头像时显示的内容 */
    /* 头部悬停效果展示区域 */
    
    .navigation {
        padding-bottom: 10px;
    }
    
    .q {
        width: 600px;
        height: 200px;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: -1;
        z-index: 100;
        position: absolute;
        top: 70px;
        display: none;
    }
    
    .tow_box:hover>.q {
        display: block;
    }
    /* 头部导航 */
    
    .wei_a>ul {
        display: flex;
        justify-content: center;
    }
    
    .wei>ul>li:hover {
        color: #F62959;
    }
    
    .c {
        width: 850px;
    }
    
    .b {
        width: 680px;
    }
    
    .d {
        left: 754px;
    }
    /* 悬停 */
    
    .he_content {
        cursor: pointer;
    }
    
    .he_content:hover {
        background-color: #F62959;
        color: #ffffff;
    }
    
    .he_content:hover>.he_lfe {
        display: none;
    }
    /* 精灵图 */
    
    .he_lfe {
        width: 24px;
        height: 24px;
        background-image: url('../src/assets/elf.png');
    }
    /* 段子段子段子段子段子段子段子段子段子段子段子段子段子段子段子段子 */
    /* 全部段子 */
    /* 这是全部段子鼠标悬停后显示的 */
    
    .he_lfe_a_a {
        background-position: -102px -0;
        display: none;
    }
    
    .he_content:hover>.he_lfe_a_a {
        display: block;
    }
    /* 段子中的沙雕段子 */
    
    .he_lfe_a {
        background-position: 0px -34px;
    }
    /* 这是沙雕段子鼠标悬停后显示的 */
    
    .he_lfe_a_a_a {
        background-position: -102px -34px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_a_a_a {
        display: block;
    }
    /* 段子中的搞笑段子 */
    
    .he_lfe_b {
        background-position: 0px -68px;
    }
    /* 这是搞笑段子鼠标悬停后显示的 */
    
    .he_lfe_b_b {
        background-position: -102px -68px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_b_b {
        display: block;
    }
    /* 段子中的内涵段子 */
    
    .he_lfe_c {
        background-position: 0px -102px;
    }
    /* 这是内涵段子鼠标悬停后显示的 */
    
    .he_lfe_c_c {
        background-position: -102px -102px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_c_c {
        display: block;
    }
    /* 短剧本短剧本短剧本短剧本短剧本短剧本短剧本短剧本短剧本短剧本短剧本短剧本 */
    
    .he_lfe_a_a {
        background-position: -102px -0;
        display: none;
    }
    
    .he_content:hover>.he_lfe_a_a {
        display: block;
    }
    /* 搞笑反转 */
    
    .he_lfe_d {
        background-position: 0px -136px;
    }
    /* 这是搞笑反转鼠标悬停后显示的 */
    
    .he_lfe_d_d {
        background-position: -102px -136px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_d_d {
        display: block;
    }
    /* 宠物萌宠 */
    
    .he_lfe_e {
        background-position: 0px -169px;
    }
    /* 这是宠物萌宠悬停之后显示的 */
    
    .he_lfe_e_e {
        background-position: -102px -169px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_e_e {
        display: block;
    }
    /* 购物营销 */
    
    .he_lfe_f {
        background-position: 0px -204px;
    }
    /* 这是购物营销鼠标悬停之后显示的 */
    
    .he_lfe_f_f {
        background-position: -102px -204px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_f_f {
        display: block;
    }
    /* 家庭生活 */
    
    .he_lfe_g {
        background-position: 0px -238px;
    }
    /* 这是家庭生活鼠标悬停之后显示的 */
    
    .he_lfe_g_g {
        background-position: -102px -238px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_g_g {
        display: block;
    }
    /* 社会职场 */
    
    .he_lfe_h {
        background-position: 0px -272px;
    }
    /* 这是社会职场鼠标悬停之后显示的 */
    
    .he_lfe_h_h {
        background-position: -102px -272px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_h_h {
        display: block;
    }
    /* 校园生活 */
    
    .he_lfe_i {
        background-position: 0px -304px;
    }
    /* 这是校园生活鼠标悬停之后的效果 */
    
    .he_lfe_i_i {
        background-position: -102px -304px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_i_i {
        display: block;
    }
    /* 情侣日常 */
    
    .he_lfe_j {
        background-position: 0px -340px;
    }
    /* 这是情侣日常鼠标悬停之后显示的 */
    
    .he_lfe_j_j {
        background-position: -102px -340px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_j_j {
        display: block;
    }
    /* 正能量 */
    
    .he_lfe_k {
        background-position: 0px -372px;
    }
    /* 这是正能量鼠标悬停之后显示的 */
    
    .he_lfe_k_k {
        background-position: -102px -372px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_k_k {
        display: block;
    }
    /* 沙雕 */
    
    .he_lfe_l {
        background-position: 0px -408px;
    }
    /* 这是沙雕鼠标悬停之后显示的 */
    
    .he_lfe_l_l {
        background-position: -102px -408px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_l_l {
        display: block;
    }
    /* 心灵鸡汤 */
    
    .he_lfe_m {
        background-position: 0px -442px;
    }
    /* 这是心灵鸡汤鼠标鼠标悬停之后显示的 */
    
    .he_lfe_m_m {
        background-position: -102px -442px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_m_m {
        display: block;
    }
    /* 经典美文 */
    
    .he_lfe_n {
        background-position: -34px 0px;
    }
    /* 这是经典美文鼠标悬停之后显示的 */
    
    .he_lfe_n_n {
        background-position: -136px -0px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_n_n {
        display: block;
    }
    /* 情感小品*/
    
    .he_lfe_o {
        background-position: -34px -34px;
    }
    /* 这是情感小品鼠标悬停之后的 */
    
    .he_lfe_o_o {
        background-position: -136px -34px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_o_o {
        display: block;
    }
    /* 灵异故事 */
    
    .he_lfe_p {
        background-position: -34px -68px;
    }
    /* 这是灵异故事鼠标悬停后的效果 */
    
    .he_lfe_p_p {
        background-position: -136px -68px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_p_p {
        display: block;
    }
    /* 娱乐小品 */
    
    .he_lfe_q {
        background-position: -34px -100px;
    }
    /* 这是娱乐小品鼠标悬停后显示的 */
    
    .he_lfe_q_q {
        background-position: -136px -100px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_q_q {
        display: block;
    }
    /* 寓言童话 */
    
    .he_lfe_r {
        background-position: -34px -137px;
    }
    /* 这是寓言故事鼠标悬停后显示的 */
    
    .he_lfe_r_r {
        background-position: -136px -137px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_r_r {
        display: block;
    }
    /* 演讲公文 */
    
    .he_lfe_s {
        background-position: -34px -170px;
    }
    /* 这是演讲公文鼠标悬停后显示的 */
    
    .he_lfe_s_s {
        background-position: -136px -170px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_s_s {
        display: block;
    }
    /* 神话传说 */
    
    .he_lfe_t {
        background-position: -34px -204px;
    }
    /* 这是神话传说鼠标悬停后显示的 */
    
    .he_lfe_t_t {
        background-position: -136px -204px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_t_t {
        display: block;
    }
    /* 运营教程 */
    
    .he_lfe_u {
        background-position: -34px -237px;
    }
    /* 这是运营教程鼠标悬停之后显示的 */
    
    .he_lfe_u_u {
        background-position: -136px -237px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_u_u {
        display: block;
    }
    /* 行业资讯 */
    
    .he_lfe_v {
        background-position: -34px -272px;
    }
    /* 这是行业资讯鼠标悬停之后显示的 */
    
    .he_lfe_v_v {
        background-position: -136px -272px;
        display: none;
    }
    
    .he_content:hover>.he_lfe_v_v {
        display: block;
    }
</style>