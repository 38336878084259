<template>
  <div>
    <!-- 企业实力 -->
    <div class="corporate_strength-box">
      <div class="corporate_strength-background">
        <img src="../../assets/home/20.png" alt="" />
      </div>
      <div class="corporate_strength">
        <div class="corporate_strength-one">
          <div class="strength-one">
            <i> <img src="../../assets/home/38.png" alt="" /></i>
            <p>剧本总数</p>
          </div>
          <div class="strength-tow"></div>
          <div class="strength-three">
            <h2>{{ data.script }}</h2>
          </div>
        </div>
        <div class="corporate_strength-one">
          <div class="strength-one">
            <i><img src="../../assets/home/22.png" alt="" /></i>
            <p>编剧</p>
          </div>
          <div class="strength-tow"></div>
          <div class="strength-three">
            <h2>{{ data.Screenwriter }}</h2>
          </div>
        </div>
        <div class="corporate_strength-one">
          <div class="strength-one">
            <i><img src="../../assets/home/23.png" alt="" /></i>
            <p>配音师</p>
          </div>
          <div class="strength-tow"></div>
          <div class="strength-three">
            <h2>{{ data.Dubber }}</h2>
          </div>
        </div>
        <div class="corporate_strength-one">
          <div class="strength-one">
            <i><img src="../../assets/home/24.png" alt="" /></i>
            <p>导演&演员</p>
          </div>
          <div class="strength-tow"></div>
          <div class="strength-three">
            <h2>{{ data.director }}</h2>
          </div>
        </div>
        <div class="corporate_strength-one">
          <div class="strength-one">
            <i><img src="../../assets/home/25.png" alt="" /></i>
            <p>服务机构</p>
          </div>
          <div class="strength-tow"></div>
          <div class="strength-three">
            <h2>{{ data.organization }}</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="bottom-box">
      <div class="bottom">
        <!-- 底部左边 -->
        <div class="bottom-left">
          <!-- 底部logo -->
          <div class="bottom-left-logo">
            <!-- <i><img src="../../assets/home/logo.png" alt="" /></i> -->
            <i><img :src="data.logo1" alt="" /></i>
            <!-- <p>宣传标语宣传标语</p> -->
          </div>
          <!-- 备案号 -->
          <div class="put_on_records">
            <p>
              备案号：<span class="reda">
                <a href="https://beian.miit.gov.cn/" target="_blank">
                  {{ data.beian }}</a>
              </span>
              |
              <span class="reda" @click="businesslicense">营业执照</span>
            </p>
          </div>
          <!-- <div class="put_on_records-font">
            <i><img src="../../assets/home/26.png" alt="" /></i>
            <p> 沪公网安备31011502006998号 </p>
          </div> -->
          <!-- 经营许可证编号 -->
          <div>
            <p>
              网络文化经营许可证: <span>{{ data.zzbh }}</span>
            </p>
          </div>
          <!-- 版权 -->
          <div>
            <p>Copyright © 2021 {{ data.title }} 版权所有</p>
          </div>
          <!-- 技术支持 -->
          <div>
            <p>
              <!-- <a href="http://www.yy371.cn" target="_blank">技术支持：远洋科技</a> -->
            </p>
          </div>
          <!-- 下面三个图片 -->
          <!-- <div>
            <i><img src="../../assets/home/28.png" alt="" /></i>
            <i><img src="../../assets/home/27.png" alt="" /></i>
            <i><img src="../../assets/home/30.png" alt="" /></i>
          </div> -->
        </div>
        <!-- 底部中间 -->
        <div class="bottom-middle">
          <div class="bottom-middle-left"></div>
          <div class="bottom-middle-right">
            <div class="message-one">
              <ul>
               <li @click="goD"><p>{{guanyu}}</p></li>
              </ul>
            </div>
            <!-- 客服信息 -->
            <div class="message-one">
              <ul>
                
                <li>
                  <p>联系我们</p>
                  <ul class="friendLink">
                    <el-popover placement="top" width="200" trigger="hover">
                      <div class="content2">
                        <div class="text2">
                          <p>{{ data.servicephone }}</p>
                        </div>
                      </div>
                      <li slot="reference">
                        {{ servicephone }}
                      </li>
                    </el-popover>
                    <el-popover placement="top" width="200" trigger="hover">
                      <div class="tips-content">
                        <div class="triangle_vw"></div>
                        <div class="content1">
                          <div class="code">
                            <img :src="data.Qimg" alt="" />
                          </div>
                          <div class="text">
                            <p>QQ扫一扫添加</p>
                            <p>我要短剧本官方QQ</p>
                          </div>
                        </div>
                      </div>
                      <li slot="reference">
                        {{ serviceqq }}
                      </li>
                    </el-popover>

                    <el-popover placement="top" width="200" trigger="hover">
                      <div class="tips-content">
                        <div class="triangle_vw"></div>
                        <div class="content1">
                          <div class="code">
                            <img :src="data.wxImg2" alt="" />
                          </div>
                          <div class="text">
                            <p>微信扫一扫添加</p>
                            <p>我要短剧本官方微信</p>
                          </div>
                        </div>
                      </div>
                      <li slot="reference">
                        {{ serviceweixin }}
                      </li>
                    </el-popover>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- 友情链接 -->
            <div class="message-one" style="margin-left:50px">
              <ul>
                <li>
                  <p>友情链接</p>
                  <ul class="friendLink">
                    <li @click="toFriendLink1">{{ friendship1 }}</li>
                    <li @click="toFriendLink2">{{ friendship2 }}</li>
                    <li @click="toFriendLink3">{{ friendship3 }}</li>
                    <li @click="toFriendLink4">{{ friendship4 }}</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 底部右边 -->
        <div class="bottom-right">
          <p>客服微信号<span style="margin-left: 60px;">编剧招聘</span></p>
          <div class="QR_codeimgbox">
            <img style="max-width: 120px" :src="data.wxImg1" alt="" />
            <img style="max-width: 120px" :src="data.wxImg2" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 营业执照 -->
    <div class="abox" v-show="xianying">
      <!-- 内容 -->
      <div class="abox-a">
        <!-- 关闭按钮 -->
        <button class="abox-b" @click="guanbi">✖</button>
        <img :src="data.zhizhao" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
    import axios from "axios";
    export default {
        data() {
            return {
                servicephone: "客服电话",
                serviceweixin: "客服微信",
                serviceqq: "客服QQ",
                guanyu: "关于我们",
                friendship1: "抖音短视频",
                friendship2: "快手小视频",
                friendship3: "火山小视频",
                friendship4: "腾讯视频",
                // friendship5:"",
                data: {},
                xianying: false,
            };
        },
        mounted() {
            axios.get("/api/shopmini/wap_Bottom.html").then((res) => {
                // console.log(res);
                sessionStorage.setItem('is_pop', res.data.is_pop)
                sessionStorage.setItem("bigdatga", JSON.stringify(res))
                this.data = res.data;
            });
        },
        methods: {
            // 营业执照点击打开
            businesslicense() {
                this.xianying = true;
            },
            // 营业执照关闭按钮
            guanbi() {
                this.xianying = false;
            },
            toFriendLink1() {
                window.open(this.data.friendship1, "_blank");
            },
            toFriendLink2() {
                window.open(this.data.friendship2, "_blank");
            },
            toFriendLink3() {
                window.open(this.data.friendship3, "_blank");
            },
            toFriendLink4() {
                window.open(this.data.friendship4, "_blank");
            },
            // 关于我们
            goD() {
                this.$router.push({
                    name: "Guanyu"
                });
            },
        },
    };
</script>

<style scoped>
    .bjzp {}
    
    .triangle_vw {
        width: 8px;
        height: 8px;
        background-color: white;
        position: absolute;
        top: 20px;
        right: -5px;
        transform: rotate(45deg);
    }
    /* 联系 */
    
    .content1 {
        width: 100%;
        height: 165px;
        padding-top: 15px;
    }
    
    .content2 {
        width: 100%;
        height: 25px;
        padding: 5px;
    }
    
    .text2 {
        width: 100%;
        margin: auto;
        text-align: center;
        font-size: 18px;
    }
    
    .code {
        width: 110px;
        height: 110px;
        border: 1px #707070 solid;
        margin: auto;
    }
    
    .code img {
        width: 110px;
        height: 110px;
    }
    
    .text {
        width: 110px;
        margin: auto;
        text-align: center;
        margin-top: 10px;
    }
    
    .text p {
        font-size: 12px;
        color: #333333;
    }
    
    .friendLink li:hover {
        color: #f62959;
    }
    /* 企业实力 */
    
    .corporate_strength-box {
        padding-top: 10px;
        min-width: 1200px;
        background-color: white;
    }
    
    .corporate_strength-background {
        position: absolute;
    }
    
    .corporate_strength-background img {
        width: 100%;
        height: 146px;
        min-width: 1200px;
    }
    
    .corporate_strength {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    
    .corporate_strength-one div {
        margin-top: 10px;
    }
    
    .strength-one i,
    .strength-one p {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        /* margin-top: 20px; */
        margin-bottom: 10px;
    }
    
    .strength-one i {
        width: 33px;
        height: 33px;
        transition: 0.5s;
    }
    
    .strength-one i img {
        width: 33px;
        height: 33px;
    }
    
    .corporate_strength-one:hover h2 {
        color: #f62959;
    }
    
    .corporate_strength-one:hover i {
        transform: rotate(360deg);
    }
    
    .strength-one p {
        margin-left: 10px;
        color: #434343;
        font-size: 24px;
    }
    
    .strength-tow {
        width: 40px;
        height: 3px;
        background-color: #e0dedf;
        margin-left: 3%;
    }
    
    .strength-three {
        height: 40%;
    }
    
    .strength-three h2 {
        color: #666666;
        font-size: 28px;
    }
    /* 底部 */
    
    .bottom-box {
        width: 100%;
        min-width: 1200px;
        background-color: #f0f3f5;
        margin-top: 21px;
        z-index: 10;
        position: absolute;
        padding-top: 36px;
    }
    
    .bottom {
        width: 1200px;
        margin: auto;
        display: flex;
    }
    /* 底部左边 */
    
    .bottom-left {
        width: 33.3%;
        height: 200px;
    }
    
    .bottom-left p {
        font-size: 14px;
        color: #333333;
        line-height: 30px;
    }
    /* 底部logo */
    
    .bottom-left-logo {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
    }
    
    .bottom-left-logo i {
        width: 180px;
    }
    
    .bottom-left-logo img {
        width: 100%;
    }
    
    .bottom-left-logo p {
        margin-left: 10px;
        font-size: 16px;
    }
    /* 备案号 */
    
    .put_on_records span {
        cursor: pointer;
    }
    
    .put_on_records-font {
        display: flex;
        align-items: center;
    }
    
    .put_on_records-font>i>img {
        vertical-align: middle;
    }
    
    .put_on_records-font p {
        cursor: pointer;
    }
    
    .bottom-left div:nth-last-of-type(1) {
        margin-top: 10px;
    }
    /* .reda:hover p {
        color: #f62959;
    }
     */
    
    .reda:hover a {
        color: #f62959;
    }
    
    .reda:hover {
        color: #f62959;
    }
    /* .reda:hover :nth-child(2) {
        color: #f62959;
    } */
    /* 底部中间 */
    
    .bottom-middle {
        width: 33.3%;
        height: 200px;
        display: flex;
    }
    
    .bottom-middle-left {
        /* width: 30%; */
    }
    
    .bottom-middle-right {
        width: 80%;
        display: flex;
        justify-content: space-between;
    }
    /* 客服信息 */
    
    .bottom-middle div {
        display: inline-block;
        vertical-align: top;
    }
    
    .bottom-middle div:nth-of-type(2) {
        margin-left: 50px;
    }
    
    .message-one ul>li {
        font-size: 14px;
        color: #333333;
        line-height: 30px;
    }
    
    .message-one ul>li>p {
        height: 52px;
        line-height: 52px;
        font-weight: 600;
        text-align: center;
    }
    
    .message-one ul>li>ul>li {
        line-height: 30px;
        cursor: pointer;
    }
    /* 底部右边 */
    
    .bottom-right {
        width: 33.3%;
        height: 200px;
    }
    
    .bottom-right p {
        height: 52px;
        line-height: 52px;
        font-size: 14px;
        color: #333333;
        font-weight: 600;
    }
    
    .QR_codeimgbox {
        width: 100%;
        display: flex;
    }
    
    .QR_codeimgbox img {
        margin: 0px 10px 0px 0px;
    }
    /* 营业执照 */
    
    .abox {
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(40px);
        z-index: 999;
        position: fixed;
        top: 0;
    }
    
    .abox-a {
        width: 1000px;
        height: auto;
        /* background-color: tan; */
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .abox-b {
        width: 40px;
        height: 40px;
        font-size: 22px;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.5);
        color: white;
        position: relative;
        left: 105%;
        top: -40px;
    }
    
    .abox-a img {
        width: 100%;
        height: auto;
        margin-top: -38px;
    }
</style>